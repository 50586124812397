import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { faPlus, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { reportedQuestionsRequest } from "../../../redux/reducers/duck/adminQuestionsDuck";
import QusViewComponent from "../../../components/adminQuestionsModals/questionsViewComponent";
import AdminViewModal from "../../../components/adminViewModal/adminViewModal";
import ReportedQusEditModal from "../../../components/reprtedQusEditModal";
import SchoolEditModal from "../../../components/schoolModal/schoolModal";
import { allSchoolRequest } from "../../../redux/reducers/duck/adminSchoolDuck";
import CreateSchoolModal from "../../../components/schoolModal/schoolModal";

const schools = () => {
	const dispatch = useDispatch();

	const [showViewModal, setShowViewModal] = useState(false);
	const [viewData, setViewData] = useState("");
	const [showEditModal, setShowEditModal] = useState(false);

	const { allSchoolData } = useSelector(({ adminSchool }) => ({
		allSchoolData: adminSchool?.allSchoolData,
	}));

	useEffect(() => {
		dispatch(allSchoolRequest());
	}, []);

	const handleCloseEditModal = () => setShowEditModal(false);
	const handleCreateSchoolodal = (item) => {
		setShowEditModal(true);
	};
	const handleCloseViewModal = () => setShowViewModal(false);
	const handleShowViewModal = (item) => {
		setShowViewModal(true);
		setViewData(item?.question);
	};

	return (
		<div>
			{/* <div className="admin-question-loader">
				<Spinner color="white" />
			</div> */}
			<Row className="mt-5">
				<Col sm={9}>
					<h2>School</h2>
				</Col>
				<Col sm={3}>
					<button className="saveChangesBtn" onClick={handleCreateSchoolodal}>
						Create School
					</button>
				</Col>
			</Row>
			<Row className="mt-3">
				<div className="table-area">
					{allSchoolData ? (
						<table class="table table-dark table-user-list">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">School Name</th>
								</tr>
							</thead>

							<>
								{allSchoolData &&
									allSchoolData?.records?.map((item, index) => {
										return (
											<>
												<tbody>
													<tr>
														<th scope="row">{index + 1}</th>
														<td>{item?.name}</td>
													</tr>
												</tbody>
											</>
										);
									})}
							</>
						</table>
					) : (
						<div className="text-center mt-2">Data Not Found</div>
					)}
				</div>
			</Row>
			<CreateSchoolModal
				showEditModal={showEditModal}
				handleCloseEditModal={handleCloseEditModal}
				viewData={viewData}
				// topicQuizeId={topicQuizeId}
			/>
		</div>
	);
};

export default schools;
