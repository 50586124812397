import React from "react";
import Modal from "react-bootstrap/Modal";

const AdminViewModal = (props) => {
	const { showViewModal, handleCloseViewModal, viewComponent, title, size } =
		props;

	return (
		<>
			<Modal
				show={showViewModal}
				onHide={handleCloseViewModal}
				backdrop="static"
				centered
				size={size ? size : "md"}
				aria-labelledby="contained-modal-title-vcenter"
			>
				<Modal.Header>
					<Modal.Title>{title}</Modal.Title>
					<button className="closeButton" onClick={handleCloseViewModal}>
						x
					</button>
				</Modal.Header>
				<Modal.Body>{viewComponent}</Modal.Body>
			</Modal>
		</>
	);
};

export default AdminViewModal;
