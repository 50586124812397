import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import {
	allCombinationsRequest,
	papersByCombinationsRequest,
	quizzByTopicsRequest,
	topicsByPapersRequest,
} from "../../../redux/reducers/duck/adminQuestionsDuck";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import QusEditModal from "../../../components/adminQuestionsModals/questionsEditModal";
import AdminViewModal from "../../../components/adminViewModal/adminViewModal";
import QusViewComponent from "../../../components/adminQuestionsModals/questionsViewComponent";
import { Spinner } from "react-bootstrap";

const AdminQuestions = () => {
	const dispatch = useDispatch();
	const [combination, setCombination] = useState("");
	const [papers, setPapers] = useState("");
	const [topics, setTopics] = useState("");
	const [showEditModal, setShowEditModal] = useState(false);
	const [showViewModal, setShowViewModal] = useState(false);
	const [viewData, setViewData] = useState("");

	const [topicQuizeId, setTopicQuizeId] = useState(null);

	const {
		allCombinationsData,
		papersByCombinationsData,
		topicsByPapersData,
		quizzByTopicsData,
		isQuestionLoading,
	} = useSelector(({ adminQuestions }) => ({
		allCombinationsData: adminQuestions?.allCombinationsData,
		papersByCombinationsData: adminQuestions?.papersByCombinationsData,
		topicsByPapersData: adminQuestions?.topicsByPapersData,
		quizzByTopicsData: adminQuestions?.quizzByTopicsData,
		isQuestionLoading: adminQuestions?.isQuestionLoading,
	}));

	useEffect(() => {
		dispatch(allCombinationsRequest());
	}, [dispatch]);

	// combination handler
	const handleCombination = (item) => {
		setCombination(
			item?.name + " " + item?.qualification + " " + item?.boardLevel
		);
		const combinationId = item?.id;
		dispatch(papersByCombinationsRequest(combinationId));
	};

	// papers handler
	const handlePapers = (item) => {
		setPapers(item?.name);
		const paperId = item?.id;
		dispatch(topicsByPapersRequest(paperId));
	};

	// topic handler
	const handleTopics = (item) => {
		setTopics(item?.name);
		const quizzId = item?.id;
		setTopicQuizeId(quizzId);
		dispatch(quizzByTopicsRequest(quizzId));
	};

	// edit view modal handlers
	const handleCloseEditModal = () => setShowEditModal(false);
	const handleShowEditModal = (item) => {
		setShowEditModal(true);
		setViewData(item);
	};
	const handleCloseViewModal = () => setShowViewModal(false);
	const handleShowViewModal = (item) => {
		setShowViewModal(true);
		setViewData(item);
	};

	return (
		<>
			<Row className="mt-5 pl-3">
				<Col sm={6}>
					<h2>Questions List</h2>
				</Col>
				<Col sm={6} className="text-right">
					<header className="questions-filters items-center flex content-justify-between">
						<div className="left-side">
							<div className="search_bar">
								<div className="dropdowns">
									<Dropdown>
										<Dropdown.Toggle id="dropdown-basic">
											<div className="date">
												<p>{combination || "Combinations"}</p>
											</div>
										</Dropdown.Toggle>
										<>
											<Dropdown.Menu>
												{allCombinationsData &&
													allCombinationsData?.map((item) => {
														return (
															<Dropdown.Item
																onClick={() => handleCombination(item)}
															>
																{item?.name +
																	" " +
																	item?.qualification +
																	" " +
																	item?.boardLevel}
															</Dropdown.Item>
														);
													})}
											</Dropdown.Menu>
										</>
									</Dropdown>

									<Dropdown>
										<Dropdown.Toggle id="dropdown-basic">
											<div className="date">
												<p>{papers || "Papers"}</p>
											</div>
										</Dropdown.Toggle>

										<Dropdown.Menu className="dropdown-list">
											{papersByCombinationsData &&
												papersByCombinationsData?.map((item) => {
													return (
														<>
															<Dropdown.Item onClick={() => handlePapers(item)}>
																{item?.name}
															</Dropdown.Item>
														</>
													);
												})}
										</Dropdown.Menu>
									</Dropdown>

									<Dropdown>
										<Dropdown.Toggle id="dropdown-basic">
											<div className="date">
												<p>{topics || "Topics"}</p>
											</div>
										</Dropdown.Toggle>

										<Dropdown.Menu className="dropdown-list">
											{topicsByPapersData &&
												topicsByPapersData?.map((item) => {
													return (
														<>
															<Dropdown.Item onClick={() => handleTopics(item)}>
																{item?.name}
															</Dropdown.Item>
														</>
													);
												})}
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>
						</div>
					</header>
				</Col>
			</Row>
			{isQuestionLoading ? (
				<div className="admin-question-loader">
					<Spinner color="white" />
				</div>
			) : (
				<Row className="mt-3 mx-2">
					<div className="table-area">
						{quizzByTopicsData && quizzByTopicsData[0]?.questions ? (
							<table class="table table-dark table-user-list">
								<thead>
									<tr>
										<th scope="col">#</th>
										<th scope="col">Questions</th>
										<th scope="col">Hint</th>
										<th scope="col">Answers</th>
										<th scope="col">Actions</th>
									</tr>
								</thead>
								{quizzByTopicsData &&
									quizzByTopicsData[0]?.questions?.map((item, index) => {
										return (
											<>
												<tbody>
													<tr>
														<th scope="row">{index + 1}</th>
														<td>{item?.question}</td>
														<td>{item?.hint}</td>
														<td>
															{item?.answers?.map((ans, i) => {
																return (
																	<>
																		<div>
																			{i + 1}: {ans?.answer}
																		</div>
																	</>
																);
															})}
														</td>
														<td>
															<div
																className="actions"
																onClick={() => handleShowViewModal(item)}
															>
																<FontAwesomeIcon
																	icon={faPlus}
																	className="me-2"
																/>
																View
															</div>
															<div
																className="actions mb-2"
																onClick={() => handleShowEditModal(item)}
															>
																<FontAwesomeIcon
																	icon={faPenToSquare}
																	className="me-2"
																/>
																Edit
															</div>
														</td>
													</tr>
												</tbody>
											</>
										);
									})}
							</table>
						) : (
							<div className="text-center mt-2">Data Not Found</div>
						)}
					</div>
				</Row>
			)}
			<QusEditModal
				showEditModal={showEditModal}
				handleCloseEditModal={handleCloseEditModal}
				viewData={viewData}
				topicQuizeId={topicQuizeId}
				combination={combination}
			/>
			<AdminViewModal
				title="View your Question"
				showViewModal={showViewModal}
				handleCloseViewModal={handleCloseViewModal}
				viewData={viewData}
				viewComponent={<QusViewComponent viewData={viewData} />}
			/>
		</>
	);
};

export default AdminQuestions;
