import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { startQuizRequest } from "../../../redux/reducers/duck/quizDuck";

export default function QuizResult() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { quizResultData } = useSelector(({ result }) => ({
		quizResultData: result?.quizResultData,
	}));

	const nextTopicsData = quizResultData && quizResultData?.nextTopics;
	const nextTopicImages = [
		"/images/topic1.png",
		"/images/topic2.png",
		"/images/topic3.png",
	];

	const topicsWithImages =
		nextTopicsData &&
		nextTopicsData?.map((topic, index) => ({
			...topic,
			image: nextTopicImages[index],
		}));

	// handle next-topic
	const handleNextTopic = (item) => {
		const topicId = item?.id;
		dispatch(startQuizRequest(topicId));
		navigate("/quiz/question");
	};

	return (
		<>
			<div className="quizCards">
				<div className="heading">
					<h3>Quiz Summary</h3>
				</div>
				<div className="summary">
					<div className="row">
						<div className="col-md-4">
							<div className="card">
								<div className="score">
									<div className="subject">
										<p>Your Score</p>
										<h4>
											{quizResultData?.correctAnswer}/{" "}
											{quizResultData?.correctAnswer +
												quizResultData?.inCorrectAnswer}
										</h4>
										<ul>
											<li>
												Total Attempted:{" "}
												<small>
													{" "}
													{quizResultData?.correctAnswer +
														quizResultData?.inCorrectAnswer}
												</small>{" "}
											</li>
											<li>
												Correct:{" "}
												<small>
													{quizResultData ? quizResultData?.correctAnswer : 0}
												</small>
											</li>
											<li>
												Wrong:{" "}
												<small>
													{quizResultData ? quizResultData?.inCorrectAnswer : 0}
												</small>
											</li>
										</ul>
									</div>
									<div className="circle">
										<img src="/images/circle.svg" alt="circle" />
										<p>{Math.round(quizResultData?.percentage)}%</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card">
								<div className="score">
									<div className="subject">
										<p>What you need to work on</p>
										<ul>
											{quizResultData &&
												quizResultData?.WorkOnTags?.map((item) => {
													return <li>{item}</li>;
												})}
										</ul>
									</div>
									<div className="circle">
										<img src="/images/book.png" alt="book" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="heading">
					<h3>What’s Next</h3>
				</div>
				{nextTopicsData && nextTopicsData?.length > 0 ? (
					<>
						<div className="row">
							{topicsWithImages &&
								topicsWithImages?.map((item) => {
									return (
										<>
											<div className="col-md-4">
												<div className="card">
													<div className="subject">
														<h4>{item?.name}</h4>
														<div onClick={() => handleNextTopic(item)}>
															<span className="">courser</span>
														</div>
													</div>
													<div className="footer">
														<div className="circlularBar">
															<img src="/images/video.svg" alt="video" />
														</div>
														<div className="subjectImg">
															<img src={item?.image} alt="topic" />
														</div>
													</div>
												</div>
											</div>
										</>
									);
								})}
						</div>
					</>
				) : (
					<div className="next-topics-not-found">All topics are completed</div>
				)}
			</div>
		</>
	);
}
