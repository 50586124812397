import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateTopicsRequest } from "../../redux/reducers/duck/adminTopicsDuck";

const AdminEditModal = ({
	showEditModal,
	handleCloseEditModal,
	viewData,
	paperId,
}) => {
	const [editTopic, setEditTopic] = useState(viewData?.name);
	const dispatch = useDispatch();
	const [content, setContent] = useState(viewData?.content);

	const handleFormSubmit = () => {
		const payload = {
			id: viewData?.id,
			name: editTopic,
			paperId: paperId,
			content: content,
		};
		dispatch(updateTopicsRequest(payload));
		handleCloseEditModal();
	};

	useEffect(() => {
		setEditTopic(viewData?.name);
		setContent(viewData?.content);
	}, [viewData, showEditModal]);

	const handleEditorChange = (event) => {
		setContent(event.target.value);
	};

	return (
		<Modal
			show={showEditModal}
			onHide={handleCloseEditModal}
			backdrop="static"
			centered
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			className="edit-modal"
		>
			<Modal.Header>
				<Modal.Title>Edit Topic</Modal.Title>
				<button className="closeButton" onClick={handleCloseEditModal}>
					x
				</button>
			</Modal.Header>
			<Modal.Body>
				<Form.Label>Topic</Form.Label>
				<Form.Control
					type="text"
					name="topic"
					className="input-fields"
					value={editTopic}
					onChange={(e) => {
						setEditTopic(e.target.value);
					}}
				/>

				<div>
					<Form.Label>Content</Form.Label>
					<textarea
						class="form-control"
						id="exampleFormControlTextarea1"
						rows="10"
						cols="200"
						style={{ color: "black", height: "20rem" }}
						value={content ? content : ""}
						onChange={handleEditorChange}
					></textarea>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="saveChangesBtn" onClick={handleFormSubmit}>
					Save Changes
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default AdminEditModal;
