import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	allCombinationsData: null,
	papersByCombinationsData: null,
	topicsByPapersData: null,
	quizzByTopicsData: null,
	quizzEditData: null,
	isQuestionLoading: null,
	reportedQuestionsData: null,
};

export const adminQuestionsSlice = createSlice({
	name: "adminQuestions",
	initialState: INITIAL_STATE,
	reducers: {
		// response reducers
		allCombinationsResponse(state, { payload }) {
			return {
				...state,
				allCombinationsData: payload.response,
			};
		},

		papersByCombinationsResponse(state, { payload }) {
			return {
				...state,
				papersByCombinationsData: payload.response,
			};
		},

		topicsByPapersResponse(state, { payload }) {
			return {
				...state,
				topicsByPapersData: payload.response,
			};
		},

		quizzByTopicsResponse(state, { payload }) {
			return {
				...state,
				quizzByTopicsData: payload.response,
			};
		},

		quizzEditResponse(state, { payload }) {
			return {
				...state,
				quizzEditData: payload.response,
			};
		},
		adminQuestionsLoading(state, { payload }) {
			return {
				...state,
				isQuestionLoading: payload,
			};
		},

		reportedQuestionsResponse(state, { payload }) {
			return {
				...state,
				reportedQuestionsData: payload,
			};
		},

		// request reducers
		allCombinationsRequest: (state) => state,
		papersByCombinationsRequest: (state) => state,
		topicsByPapersRequest: (state) => state,
		quizzByTopicsRequest: (state) => state,
		quizzEditRequest: (state) => state,
		reportedQuestionsRequest: (state) => state,
	},
});

export const {
	allCombinationsRequest,
	allCombinationsResponse,
	papersByCombinationsResponse,
	papersByCombinationsRequest,
	topicsByPapersResponse,
	topicsByPapersRequest,
	quizzByTopicsRequest,
	quizzByTopicsResponse,
	quizzEditRequest,
	quizzEditResponse,
	adminQuestionsLoading,
	reportedQuestionsRequest,
	reportedQuestionsResponse,
} = adminQuestionsSlice.actions;

export default adminQuestionsSlice.reducer;
