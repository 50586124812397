import React, { useState, useEffect } from "react";
import dashboard from "../../../assets/images/dashboard.svg";
import content from "../../../assets/images/content.svg";
import quiz from "../../../assets/images/quiz.svg";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";

const AdminSidebar = () => {
	const location = useLocation();
	const [activeImage, setActiveImage] = useState(() => {
		const storedImage = localStorage.getItem("activeImage");
		return storedImage ? storedImage : dashboard;
	});

	useEffect(() => {
		const path = location.pathname;
		if (path === "/") {
			setActiveImage(dashboard);
		} else if (path.startsWith("/users")) {
			setActiveImage(content);
		} else if (path.startsWith("/questions")) {
			setActiveImage(quiz);
		}
	}, [location.pathname]);

	useEffect(() => {
		localStorage.setItem("activeImage", activeImage);
	}, [activeImage]);

	const handleImageClick = (image) => {
		setActiveImage(image);
	};
	return (
		<>
			<Sidebar>
				<div className="logo">
					<img src="/images/logo.png" className="sidebar-logo" alt="logo" />
					<h5>MyScienceLand</h5>
				</div>
				<Menu>
					<MenuItem
						onClick={() => handleImageClick(dashboard)}
						component={<Link to="/dashboard" />}
						className={activeImage === dashboard ? "active-link" : ""}
					>
						<img src={dashboard} alt="icon" />
						<p>Dashboard</p>
					</MenuItem>
					<MenuItem
						onClick={() => handleImageClick(content)}
						component={<Link to="/users" />}
						className={activeImage === content ? "active-link" : ""}
					>
						<img src={content} alt="icon" />
						<p>Users</p>
					</MenuItem>
					<MenuItem
						onClick={() => handleImageClick(quiz)}
						component={<Link to="/questions" />}
						className={activeImage === quiz ? "active-link" : ""}
					>
						<img src={quiz} alt="icon" />
						<p>Questions</p>
					</MenuItem>
					<MenuItem
						onClick={() => handleImageClick("topics")}
						component={<Link to="/topics" />}
						className={activeImage === "topics" ? "active-link" : ""}
					>
						<img src={quiz} alt="icon" />
						<p>Topics</p>
					</MenuItem>
					<MenuItem
						onClick={() => handleImageClick("reported")}
						component={<Link to="/reported" />}
						className={activeImage === "reported" ? "active-link" : ""}
					>
						<img src={quiz} alt="icon" />
						<p>Reported</p>
					</MenuItem>
					<MenuItem
						onClick={() => handleImageClick("schools")}
						component={<Link to="/schools" />}
						className={activeImage === "schools" ? "active-link" : ""}
					>
						<img src={quiz} alt="icon" />
						<p>Schools</p>
					</MenuItem>
				</Menu>
			</Sidebar>
		</>
	);
};

export default AdminSidebar;
