import { combineReducers } from "@reduxjs/toolkit";
import auth from "./duck/authDuck";
import content from "./duck/contentDuck";
import quiz from "./duck/quizDuck";
import dashboard from "./duck/dashboardDuck";
import user from "./duck/userDuck";
import result from "./duck/resultDuck";
import adminQuestions from "./duck/adminQuestionsDuck";
import adminTopics from "./duck/adminTopicsDuck";
import primiarly from "./duck/primiarlyQuizeDuck";
import adminSchool from "./duck/adminSchoolDuck";

const rootReducers = combineReducers({
	auth,
	content,
	quiz,
	dashboard,
	user,
	result,
	adminQuestions,
	adminTopics,
	primiarly,
	adminSchool,
});

export default rootReducers;
