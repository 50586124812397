import React from "react";
import Modal from "react-bootstrap/Modal";

const QusViewComponent = (props) => {
	const { viewData } = props;

	return (
		<>
			<table className="table table-dark table-user-list">
				<thead>
					<tr>
						<th scope="col">#</th>
						<th scope="col">Question</th>
						<th scope="col">Hint</th>
						<th scope="col">Answers</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row">1</th>
						<td>{viewData?.question}</td>
						<td>{viewData?.hint}</td>
						<td>
							{viewData?.answers?.map((ans, i) => {
								return (
									<>
										<div>
											{i + 1}: {ans?.answer}
										</div>
									</>
								);
							})}
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};

export default QusViewComponent;
