import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	allSchoolData: null,
};

export const adminSchoolSlice = createSlice({
	name: "adminSchoolSlice",
	initialState: INITIAL_STATE,
	reducers: {
		// response reducers
		allSchoolResponse(state, { payload }) {
			return {
				...state,
				allSchoolData: payload.response,
			};
		},
		// request reducers
		allSchoolRequest: (state) => state,
		updateSchoolRequest: (state) => state,
		createSchoolRequest: (state) => state,
		deleteSchoolRequest: (state) => state,
	},
});

export const {
	allSchoolRequest,
	allSchoolResponse,
	updateSchoolRequest,
	createSchoolRequest,
	deleteSchoolRequest,
} = adminSchoolSlice.actions;

export default adminSchoolSlice.reducer;
