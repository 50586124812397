import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";

import {
	primarilyQuizeIdResponse,
	primiarlyConfirmationRequest,
	primiarlyQuizeStartResponse,
} from "../../../redux/reducers/duck/primiarlyQuizeDuck";
import { Link } from "react-router-dom";
import { nextQuestionRequest } from "../../../redux/reducers/duck/quizDuck";
import { getSubjectPrefRequest } from "../../../redux/reducers/duck/dashboardDuck";

function PrimarilyModal(props) {
	const { show, handleClose, modalShowStatus } = props;
	const dispatch = useDispatch();

	// const [primarilyQuizeId, setIsPrimarilyQuizeId] = useState(null);

	const { primiarlyConfirmationData, getSubjectsPrefData } = useSelector(
		({ primiarly, dashboard }) => ({
			primiarlyConfirmationData: primiarly?.primiarlyConfirmationData?.data,
			primiarlyQuizeData: primiarly?.primiarlyQuizeData?.QuizIds,
			getSubjectsPrefData: dashboard?.getSubjectsPrefData,
		})
	);

	const { quizResultData } = useSelector(({ result }) => ({
		quizResultData: result?.quizResultData,
	}));

	const handlePrimarilyQuestion = (prefId) => {
		for (let i = 0; i < primiarlyConfirmationData?.length; i++) {
			if (primiarlyConfirmationData[i].id === prefId) {
				const quizeId = primiarlyConfirmationData[i]?.QuizData[0]?.id;
				dispatch(primiarlyQuizeStartResponse(true));
				dispatch(nextQuestionRequest(quizeId));
				dispatch(primarilyQuizeIdResponse(quizeId));
				break;
			}
		}
	};

	useEffect(() => {
		setTimeout(() => {
			dispatch(primiarlyConfirmationRequest());
			dispatch(primiarlyQuizeStartResponse(false));
			dispatch(getSubjectPrefRequest());
		}, 1000);
	}, [quizResultData]);

	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				centered
				aria-labelledby="contained-modal-title-vcenter"
			>
				<Modal.Header>
					<Modal.Title>Please Attempt Primarily Quiz</Modal.Title>
					{modalShowStatus && (
						<button className="closeButton" onClick={handleClose}>
							x
						</button>
					)}
				</Modal.Header>
				<Modal.Body>
					<div className="primarily-text-color">
						<div className="mt-3 primarily-text-color">
							Thank you for submitting your preferences! In order to ensure the
							best possible experience and tailor our offerings to your needs,
							we kindly request that you attempt a preliminary quiz.
						</div>
						<div>
							This quiz serves as a means for us to gauge your previous
							knowledge and understanding, allowing us to provide you with the
							most relevant and beneficial content.
						</div>
						<div>
							Your participation in this quiz will greatly assist us in
							customizing our services to meet your specific requirements.
						</div>
						<div>
							We appreciate your cooperation and look forward to assisting you
							further on your learning journey!"
						</div>
						<div>You selected following preference</div>
						{getSubjectsPrefData?.preference &&
							getSubjectsPrefData?.preference?.length > 0 && (
								<>
									<div className="row mt-4">
										<div className="selected-pref-heading">
											Your Selected Preferences
										</div>
									</div>

									<div className="row mt-2">
										{getSubjectsPrefData?.preference?.map((item, index) => {
											return (
												<>
													{primiarlyConfirmationData &&
														primiarlyConfirmationData?.length > 0 &&
														!primiarlyConfirmationData[index]?.isPrimiarly && (
															<div className="col-4">
																<div>{item?.subject?.qualification}</div>
																<div>{item?.subject?.name}</div>
																<div>{item?.subject?.boardLevel}</div>
																<div
																	onClick={() => {
																		handlePrimarilyQuestion(item?.id);
																	}}
																>
																	<Link
																		to="/quiz/question"
																		className="primarily-questions-link"
																	>
																		<button className="start-btn" type="submit">
																			Start
																		</button>
																	</Link>
																</div>
															</div>
														)}
												</>
											);
										})}
									</div>
								</>
							)}
						<div className="mt-5 primarily-text-color">
							Please click on selected prefernce for attempting primarily Quiz
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default PrimarilyModal;
