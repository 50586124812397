import { all, fork } from "redux-saga/effects";
import { watchAuthSagas } from "./sagas/authSaga";
import { watchContentSagas } from "./sagas/contentSaga";
import { watchQuizSagas } from "./sagas/quizSaga";
import { watchDashboardSagas } from "./sagas/dashboardSaga";
import { watchUserSagas } from "./sagas/userSaga";
import { watchQuizResultSagas } from "./sagas/resultSaga";
import { watchAdminQuestionsSagas } from "./sagas/adminQuestionsSaga";
import { watchAdminTopicSagas } from "./sagas/adminTopicsSaga";
import { watchPrimiarlySagas } from "./sagas/primiarlyQuizeSaga";
import { watchAdminSchoolSagas } from "./sagas/adminSchoolSaga";

const rootSaga = function* () {
	yield all([fork(watchContentSagas)]);
	yield all([fork(watchAuthSagas)]);
	yield all([fork(watchQuizSagas)]);
	yield all([fork(watchDashboardSagas)]);
	yield all([fork(watchUserSagas)]);
	yield all([fork(watchQuizResultSagas)]);
	yield all([fork(watchAdminQuestionsSagas)]);
	yield all([fork(watchAdminTopicSagas)]);
	yield all([fork(watchPrimiarlySagas)]);
	yield all([fork(watchAdminSchoolSagas)]);
};

export default rootSaga;
