import React from "react";
import Charts from "react-apexcharts";
import { useSelector } from "react-redux";
import "./charts.css";

export default function Chart({ percentage }) {
	const { dashboardGraphData } = useSelector(({ dashboard }) => ({
		dashboardGraphData: dashboard?.dashboardGraphData,
	}));

	const chartData =
		dashboardGraphData &&
		dashboardGraphData?.userQuiz?.map((item) => item?.finishPoints);

	console.log(dashboardGraphData);
	const chartVersions =
		dashboardGraphData &&
		dashboardGraphData?.userQuiz?.map((item) => item?.version);

	if (chartData && dashboardGraphData && dashboardGraphData?.userQuiz[0]) {
		chartData && chartData?.unshift(dashboardGraphData?.userQuiz[0].points);
	}

	console.log(chartData, "chartData");
	chartVersions && chartVersions?.unshift("Baseline");

	const state = {
		chart: {
			type: "area",
			stacked: false,
			height: 350,
			zoom: {
				type: "x",
				enabled: true,
				autoScaleYaxis: true,
			},
			toolbar: {
				autoSelected: "zoom",
				show: false,
			},
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: 0,
		},
		fill: {
			type: "gradient",
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.5,
				opacityTo: 0,
				stops: [0, 100],
				colorStops: [
					{
						offset: 0,
						color: "rgba(29, 87, 238, 0.20)",
					},
					{
						offset: 100,
						color: "rgba(212, 29, 176, 0.20)",
					},
				],
			},
		},
		series: [
			{
				name: "Points",
				data: chartData,
			},
		],
		grid: {
			show: false,
		},
		xaxis: {
			categories: chartVersions,
			axisTicks: {
				show: false,
			},
			title: {
				cssClass: "chart-show",
			},
		},
		tooltip: {
			shared: false,
			cssClass: "chart-show",
		},
	};

	return (
		<>
			<div id="chart">
				<div className="heading">
					<h3 className="mt-2">Subjects</h3>
				</div>
				{dashboardGraphData && dashboardGraphData?.userQuiz?.length > 0 ? (
					<div className="card">
						<Charts
							options={state}
							series={state?.series}
							type="area"
							height={350}
						/>
					</div>
				) : (
					<div className="card graph-data">Graph Data does not exist</div>
				)}
			</div>
		</>
	);
}
