import Axios from "./axios";
import urls from "./url";

export const allSchoolsApi = async () => {
	const token = localStorage.getItem("token");
	try {
		return await Axios.get(urls?.admin?.allSchools, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const createSchoolApi = async (data) => {
	const token = localStorage.getItem("token");

	try {
		return await Axios.post(urls?.admin?.createSchools, data, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};
