// useLocalStorageRedirect.js

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useIsPrimarily() {
	const { primiarlyConfirmationData } = useSelector(({ primiarly }) => ({
		primiarlyConfirmationData: primiarly?.primiarlyConfirmationData?.data,
	}));
	const [isPrimarily, setIsPrimarily] = useState(true);

	useEffect(() => {
		let isPrimery = true;
		if (primiarlyConfirmationData && primiarlyConfirmationData?.length > 0) {
			for (let i = 0; i < primiarlyConfirmationData?.length; i++) {
				if (primiarlyConfirmationData[i].isPrimiarly === false) {
					isPrimery = false;
					break;
				}
			}
		}

		setIsPrimarily(isPrimery);
	}, [primiarlyConfirmationData]);

	return isPrimarily;
}

export default useIsPrimarily;
