import { createSlice } from "@reduxjs/toolkit";

// Initial State
export const INITIAL_STATE = {
	userData: null,
	adminUserListData: null,
};

export const userSlice = createSlice({
	name: "user",
	initialState: INITIAL_STATE,
	reducers: {
		// response reducers
		userResponse(state, { payload }) {
			return {
				...state,
				userData: payload.response,
			};
		},
		adminUserListResponse(state, { payload }) {
			return {
				...state,
				adminUserListData: payload.response,
			};
		},
		// request reducers
		userRequest: (state) => state,
		adminUserListRequest: (state) => state,
	},
});
export const {
	userRequest,
	userResponse,
	adminUserListResponse,
	adminUserListRequest,
} = userSlice.actions;
export default userSlice.reducer;
