import Axios from "./axios";
import urls from "./url";

// all-combinations api
export const allCombinationsApi = async () => {
	const token = localStorage.getItem("token");
	try {
		return await Axios.get(urls?.admin?.allCombinations, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

// papers-by-combinations api
export const papersByCombinationsApi = async (id) => {
	const token = localStorage.getItem("token");
	try {
		return await Axios.get(`paper/${id}/get-by-subject`, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

// topics-by-papers api
export const topicsByPapersApi = async (id) => {
	const token = localStorage.getItem("token");
	try {
		return await Axios.get(`topic/${id}/get-by-paper`, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

// quizz-by-topics api
export const quizzByTopicsApi = async (id) => {
	const token = localStorage.getItem("token");
	try {
		return await Axios.get(`quiz/${id}/get-by-topic`, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

// quizz-edit api
export const quizzEditApi = async (data) => {
	const token = localStorage.getItem("token");
	try {
		return await Axios.put(`/question/${data?.id}`, data, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

// reported-questions api
export const reportedQuestionsApi = async () => {
	const token = localStorage.getItem("token");
	try {
		return await Axios.get(urls?.admin?.reportedQuestions, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};
