const AdminTopicView = ({ viewData }) => {
	return (
		<div className="p-2 topics-view-modal-filters">
			{viewData ? (
				<div>
					<div
						className="text-div"
						dangerouslySetInnerHTML={{ __html: viewData }}
					/>
				</div>
			) : (
				<div>No Content Found</div>
			)}
		</div>
	);
};

export default AdminTopicView;
