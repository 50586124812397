import { useEffect, useState } from "react";
import UserSidebar from "../../components/sidebars/userSidebar";
import Dropdown from "react-bootstrap/Dropdown";
import Bar from "../../assets/images/bar.png";
import { useDispatch, useSelector } from "react-redux";
import { userRequest } from "../../redux/reducers/duck/userDuck";
import {
	dashboardGraphRequest,
	dashboardPendingQuizRequest,
	dashboardSubjectTopicsRequest,
	getSubjectPrefRequest,
} from "../../redux/reducers/duck/dashboardDuck";
import PrefrenceModal from "../prefrenceModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { subjectRequest } from "../../redux/reducers/duck/contentDuck";
import { useLocation, useNavigate } from "react-router-dom";
import useIsPrimarily from "../../hooks/primarilyHook";
import PrimarilyModal from "./primarilyModal/primarilyModal";
import { Spinner } from "react-bootstrap";

export const PanelLayout = ({ children }) => {
	const dispatch = useDispatch();
	const location = useLocation();

	const [openSidebar, setOpenSidebar] = useState(true);
	const [show, setShow] = useState(false);
	const [selectedTopic, setSelectedTopic] = useState("");
	const [subject, setSubject] = useState("");
	const [showPrimarilyModal, setShowPrimarilyModal] = useState(false);
	const {
		userData,
		subjectsData,
		getSubjectsPrefData,
		dashboardSubjectTopicsData,
		isPrimarilyQuizeStart,
		isPrimarilyLoading,
		primarilyQuizeId,
	} = useSelector(({ user, dashboard, content, primiarly }) => ({
		userData: user?.userData,
		getSubjectsPrefData: dashboard?.getSubjectsPrefData,
		subjectsData: content?.subjectsData,
		dashboardSubjectTopicsData: dashboard?.dashboardSubjectTopicsData,
		primiarlyQuizeData: primiarly?.primiarlyQuizeData,
		isPrimarilyQuizeStart: primiarly?.isPrimarilyQuizeStart,
		isPrimarilyLoading: primiarly?.isPrimarilyLoading,
		isPrefernceSet: primiarly?.isPrefernceSet,
		primarilyQuizeId: primiarly?.primarilyQuizeId,
	}));

	let subjects = subjectsData?.map((item) => {
		return item?.subject;
	});

	useEffect(() => {
		dispatch(userRequest());
		dispatch(subjectRequest());
		dispatch(getSubjectPrefRequest());
	}, [dispatch]);

	let isPrimarily = useIsPrimarily();

	// handle topic dropdown
	const handleTopicSelect = (topic) => {
		const topicID = topic?.id;
		dispatch(dashboardGraphRequest(topicID));
		setSelectedTopic(topic?.name);
	};

	const currentPath = location.pathname;
	useEffect(() => {
		if (primarilyQuizeId && currentPath !== "/quiz/question") {
			setShowPrimarilyModal(true);
		} else if (primarilyQuizeId && currentPath === "/quiz/question") {
			setShowPrimarilyModal(false);
		}
	}, [primarilyQuizeId, currentPath]);

	useEffect(() => {
		if (!isPrimarily && !isPrimarilyQuizeStart) {
			setShowPrimarilyModal(true);
		} else if (!isPrimarily && isPrimarilyQuizeStart) {
			setShowPrimarilyModal(false);
		} else if (isPrimarily) {
			setShowPrimarilyModal(false);
		} else if (!isPrimarily) {
			setShowPrimarilyModal(true);
		}
	}, [isPrimarily, isPrimarilyQuizeStart]);

	// handle subejct Dropdown
	const handleSubjectSelect = (item) => {
		if (subject !== item?.name) {
			setSelectedTopic("");
		}
		setSubject(item?.name);
		const subjectID = item?.id;
		dispatch(dashboardSubjectTopicsRequest(subjectID));
		dispatch(dashboardPendingQuizRequest(subjectID));
		dispatch(dashboardGraphRequest());
	};

	const sideBarMenu = () => {
		setOpenSidebar(!openSidebar);
	};
	const navigate = useNavigate();

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const modalShowStatus = getSubjectsPrefData?.isPreferenceSet;

	useEffect(() => {
		if (getSubjectsPrefData?.isPreferenceSet === false) {
			setShow(true);
		} else {
			setShow(false);
		}
	}, [modalShowStatus]);

	// Ai-chatebot script
	// useEffect(() => {
	// 	(function (d, t) {
	// 		var v = d.createElement(t),
	// 			s = d.getElementsByTagName(t)[0];
	// 		v.onload = function () {
	// 			window.voiceflow.chat.load({
	// 				verify: { projectID: "657716cab875350007333845" },
	// 				url: "https://general-runtime.voiceflow.com",
	// 				versionID: "production",
	// 			});
	// 		};
	// 		v.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
	// 		v.type = "text/javascript";
	// 		s.parentNode.insertBefore(v, s);
	// 	})(document, "script");
	// }, []);

	// useEffect(() => {
	// 	const injectScript = document.createElement("script");
	// 	injectScript.src = "https://cdn.botpress.cloud/webchat/v1/inject.js";
	// 	injectScript.async = true;
	// 	document.body.appendChild(injectScript);

	// 	const configScript = document.createElement("script");
	// 	configScript.src =
	// 		"https://mediafiles.botpress.cloud/f6353fc1-b032-4e4f-b6b7-0931a68a8879/webchat/config.js";
	// 	configScript.defer = true;
	// 	document.body.appendChild(configScript);

	// 	return () => {
	// 		document.body.removeChild(injectScript);
	// 		document.body.removeChild(configScript);
	// 	};
	// }, []);

	return (
		<>
			<div className="panel-wrapper flex flex-wrap column-direction">
				{isPrimarily && (
					<div className={`panel-sidebar ${openSidebar ? "hide" : "show"}`}>
						<UserSidebar />
					</div>
				)}
				{isPrimarilyLoading && (
					<div className="admin-question-loader">
						<Spinner color="white" />
					</div>
				)}
				<div
					className={`panel-main-content flex column-direction ${
						openSidebar ? "hide" : "show"
					}`}
				>
					<button
						onClick={sideBarMenu}
						className={`side-menu-btn fa fa-bars ${
							openSidebar ? "hide" : "show"
						}`}
					>
						<img src={Bar} alt="bar" />
					</button>
					<header className="panel-header items-center flex content-justify-between">
						<div className="left-side">
							{location && location.pathname === "/dashboard" && (
								<div className="search_bar">
									<div className="dropdowns">
										<Dropdown>
											<Dropdown.Toggle id="dropdown-basic">
												<div className="date">
													<p>{subject || "Subjects"}</p>
												</div>
											</Dropdown.Toggle>

											<Dropdown.Menu>
												{subjects &&
													subjects?.map((item) => {
														return (
															<Dropdown.Item
																onClick={() => handleSubjectSelect(item)}
															>
																{item?.name}
															</Dropdown.Item>
														);
													})}
											</Dropdown.Menu>
										</Dropdown>

										<Dropdown>
											<Dropdown.Toggle id="dropdown-basic">
												<div className="date">
													<p>{selectedTopic || "Topics"}</p>
												</div>
											</Dropdown.Toggle>

											<Dropdown.Menu className="dropdown-list">
												{dashboardSubjectTopicsData &&
													dashboardSubjectTopicsData?.topics?.map((item) => {
														return (
															<Dropdown.Item
																onClick={() => handleTopicSelect(item)}
															>
																{item?.name}
															</Dropdown.Item>
														);
													})}
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</div>
							)}
						</div>
						<div className="right-side">
							<Dropdown>
								<Dropdown.Toggle id="dropdown-basic">
									<div className="date">
										<img
											src="./images/avatar-placeholder.jpg"
											alt="Avatar"
											width={100}
										/>
										<p>Hello, {userData?.name}</p>
									</div>
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item onClick={handleShow}>
										<FontAwesomeIcon icon={faPlus} className="me-2" />
										Add Preference
									</Dropdown.Item>
									<Dropdown.Item
										href="/login"
										onClick={() => localStorage.clear()}
									>
										<FontAwesomeIcon
											icon={faRightFromBracket}
											className="me-2"
										/>
										LogOut
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</header>
					<div className="adjust-content-space">{children}</div>
				</div>
			</div>
			<PrefrenceModal
				show={show}
				handleShow={handleShow}
				handleClose={handleClose}
				modalShowStatus={modalShowStatus}
			/>
			<PrimarilyModal
				show={showPrimarilyModal}
				handleClose={handleClose}
				modalShowStatus={!showPrimarilyModal}
			/>
		</>
	);
};
