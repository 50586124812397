import { put, call, takeLatest } from "redux-saga/effects";
import * as actions from "../../reducers/duck/userDuck";
import { userApi, adminUserListApi } from "../../../api/userApi";

// user saga
export function* userSaga() {
	try {
		const response = yield call(userApi);
		if (response?.data) {
			yield put(actions.userResponse({ response: response?.data }));
		}
		if (response?.response?.status === 401) {
			localStorage.clear();
			sessionStorage.clear();
			localStorage.removeItem("persist:root");
			window.location.href = "/login";
		}
	} catch (error) {
	} finally {
	}
}

// user saga
export function* adminUserListSaga() {
	try {
		const response = yield call(adminUserListApi);
		if (response?.data) {
			yield put(actions.adminUserListResponse({ response: response?.data }));
		}
	} catch (error) {
	} finally {
	}
}

export function* watchUserSagas() {
	yield takeLatest(actions.userRequest.type, userSaga);
	yield takeLatest(actions.adminUserListRequest.type, adminUserListSaga);
}
