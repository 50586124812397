import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Dropdown from "react-bootstrap/Dropdown";
import {
	allSchoolRequest,
	signupRequest,
} from "../../redux/reducers/duck/authDuck";
import { ButtonLoader } from "../Helper/loader";
import {
	getNameErrors,
	getEmailErrors,
	getEmailValidator,
	getPwdErrors,
	getPwdValidator,
	getConfirmPasswordErrors,
	getSchoolUserNameErrors,
} from "../../utils/authValidator";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const SignupPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [schoolUserName, setSchoolUserName] = useState();
	const [schoolUserId, setSchoolUserId] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	const [errors, setErrors] = useState({});
	const [isSchoolUser, setIsSchoolUser] = useState(false);
	const [isReCaptchaValid, setIsReCaptchaValid] = useState(false);
	const [isSignSubmitted, setIsSignSubmitted] = useState(false);

	const { signup, isLoading, allSchool } = useSelector(({ auth }) => {
		return {
			signup: auth?.signupRes,
			isLoading: auth?.isLoading,
			allSchool: auth?.allSchool,
		};
	});
	const [isEyeIconClick, setIsEyeIconClick] = useState(false);
	const [isEyeIcon, setIsEyeIcon] = useState(false);

	const findErrors = () => {
		const newErrors = {};
		if (!name) newErrors.name = getNameErrors();
		if (isSchoolUser && !schoolUserName)
			newErrors.schoolUser = getSchoolUserNameErrors();
		if (!getEmailValidator(email)) newErrors.email = getEmailErrors();
		if (!getPwdValidator(password)) newErrors.password = getPwdErrors();
		if (!confirmPassword)
			newErrors.confirmPassword = getConfirmPasswordErrors();
		return newErrors;
	};

	const handleSignup = () => {
		const newErrors = findErrors();
		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
		} else {
			setIsSignSubmitted(true);
		}
	};

	useEffect(() => {
		if (isReCaptchaValid) {
			const requestData = {
				name: name,
				email: email,
				password: password,
				confirmPassword: confirmPassword,
				roles: "user",
				schoolUserId: schoolUserId,
			};
			dispatch(signupRequest(requestData));
		}
	}, [isReCaptchaValid]);

	useEffect(() => {
		if (signup) {
			window.location.href = "/dashboard";
			// navigate('/dashboard');
		}
	}, [navigate, signup]);

	useEffect(() => {
		if (!isLoading) {
			setIsReCaptchaValid(false);
			setIsSignSubmitted(false);
		}
	}, [isLoading]);

	function onChange(value) {
		if (value) {
			setIsReCaptchaValid(true);
		} else {
			setIsReCaptchaValid(false);
		}
	}

	useEffect(() => {
		dispatch(allSchoolRequest());
	}, []);

	return (
		<>
			<div className="main-container">
				<Container className="login-container">
					<div className="form">
						<img
							src="/images/logo.png"
							width={100}
							alt="logo"
							className="logo-img"
						/>
						<h1>MyScienceLand</h1>
						<p>Sign Up To MyScienceLand</p>
						<div className="form-group">
							<label>Name</label>
							<input
								type="text"
								value={name}
								placeholder="Name *"
								required
								onChange={(e) => setName(e?.target?.value)}
							/>
							<p className="errmsg">
								{errors.name?.map((err, index) => {
									if (index === errors.name.length) return err;
									else return err + "\n";
								})}
							</p>
						</div>
						<div className="form-group">
							<label>Email</label>
							<input
								type="email"
								value={email}
								placeholder="Email *"
								required
								onChange={(e) => setEmail(e?.target?.value)}
							/>
							<p className="errmsg">
								{errors.email?.map((err, index) => {
									if (index === errors.email.length) return err;
									else return err + "\n";
								})}
							</p>
						</div>
						<div className="form-group">
							<label>Password</label>
							<div className="password-input-container">
								<input
									type={isEyeIconClick ? "text" : "password"} // Set input type dynamically
									value={password}
									placeholder="Password"
									required
									onChange={(e) => setPassword(e.target.value)}
								/>
								<span
									className="eye-icon"
									onClick={() => setIsEyeIconClick(!isEyeIconClick)}
								>
									{isEyeIconClick ? (
										<FontAwesomeIcon icon={faEye} />
									) : (
										<FontAwesomeIcon icon={faEyeSlash} />
									)}
								</span>
							</div>
							<p className="errmsg">
								{errors.password?.map((err, index) => {
									if (index === errors.password.length) return err;
									else return err + "\n";
								})}
							</p>
						</div>
						<div className="form-group">
							<label>Confirm Password</label>
							<div className="password-input-container">
								<input
									type={isEyeIcon ? "text" : "password"} // Set input type dynamically
									value={confirmPassword}
									placeholder="Confirm Password *"
									required
									onChange={(e) => setConfirmPassword(e?.target?.value)}
								/>
								<span
									className="eye-icon"
									onClick={() => setIsEyeIcon(!isEyeIcon)}
								>
									{isEyeIcon ? (
										<FontAwesomeIcon icon={faEye} />
									) : (
										<FontAwesomeIcon icon={faEyeSlash} />
									)}
								</span>
							</div>
						</div>
						<div className="checkbox-container">
							<div>
								<label className="checkbox">
									<input
										type="checkbox"
										className="checkbox-input"
										onChange={(e) => setIsSchoolUser(e.target.checked)}
									/>
									<span className="checkmark"></span>
									<span className="">I am school user</span>
								</label>
							</div>
							{isSchoolUser && (
								<Dropdown>
									<Dropdown.Toggle
										className="customDropdown"
										variant="secondary"
										id="dropdown-basic"
									>
										{schoolUserName ? schoolUserName : "Select School"}
									</Dropdown.Toggle>

									<Dropdown.Menu>
										{allSchool?.records?.map((item) => (
											<Dropdown.Item
												key={item?.id}
												onClick={() => {
													setSchoolUserId(item?.id);
													setSchoolUserName(item?.name);
												}}
											>
												{item?.name}
											</Dropdown.Item>
										))}
									</Dropdown.Menu>
								</Dropdown>
							)}
						</div>
						<p className="errmsg ms-3">
							{isSchoolUser &&
								!schoolUserName &&
								errors.schoolUser?.map((err, index) => {
									if (index === errors?.schoolUser?.length) return err;
									else return err + "\n";
								})}
						</p>
						{isSignSubmitted && (
							<div className="recaptchParent">
								<ReCAPTCHA
									style={{
										display: "inline-block",
										color: "white",
									}}
									theme="dark"
									sitekey={process.env.REACT_APP_ReactCaptcha}
									onChange={onChange}
								/>
							</div>
						)}
						{isLoading ? (
							<div className="loader-button">
								<div className="button-container">
									<ButtonLoader />
									<button type="submit">Sign Up</button>
								</div>
							</div>
						) : (
							<div className="button">
								<button type="submit" onClick={() => handleSignup()}>
									Sign Up
								</button>
							</div>
						)}
						<div className="login">
							<p>
								Already have an account? <a href="/login">login</a>
							</p>
						</div>
					</div>
				</Container>
			</div>
		</>
	);
};

export default SignupPage;
