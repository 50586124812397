import React, { useEffect } from "react";
import {
	Route,
	BrowserRouter as Router,
	Routes,
	Navigate,
} from "react-router-dom";
import NotFound from "../components/ErrorPage/ErrorPage";
import { PanelLayout } from "../components/layout/PanelLayout";
import AdminPanelLayout from "../components/adminLayout";
import { authRoutes, userRoutes, adminRoutes } from "./routesPath";

const Approutes = () => {
	const accessToken = localStorage.getItem("token");
	const currentRole = localStorage.getItem("currentRole");

	return (
		<>
			<Router>
				<Routes>
					<Route
						path="/"
						element={
							<Navigate to={!!accessToken ? "/dashboard" : "/login"} replace />
						}
					/>
					{(!!accessToken && currentRole === "user"
						? userRoutes
						: currentRole === "admin"
						? adminRoutes
						: authRoutes
					)?.map((route, i) => {
						return route.component ? (
							<Route
								key={i}
								exact={true}
								index={
									!!accessToken
										? route.path === "/dashboard"
										: route.path === "/login"
								}
								path={`${route.path}`}
								element={
									<>
										{!!accessToken && currentRole === "user" ? (
											<PanelLayout children={<route.component />} />
										) : currentRole === "admin" ? (
											<AdminPanelLayout children={<route.component />} />
										) : (
											<route.component />
										)}
									</>
								}
							/>
						) : null;
					})}
					<Route path="*" element={<NotFound />} />
				</Routes>
			</Router>
		</>
	);
};

export default Approutes;
