import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { faPlus, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { reportedQuestionsRequest } from "../../../redux/reducers/duck/adminQuestionsDuck";
import QusViewComponent from "../../../components/adminQuestionsModals/questionsViewComponent";
import AdminViewModal from "../../../components/adminViewModal/adminViewModal";
import ReportedQusEditModal from "../../../components/reprtedQusEditModal";

const ReportedQuestions = () => {
	const dispatch = useDispatch();

	const [showViewModal, setShowViewModal] = useState(false);
	const [viewData, setViewData] = useState("");
	const [showEditModal, setShowEditModal] = useState(false);

	const { reportedQuestionsData } = useSelector(({ adminQuestions }) => ({
		reportedQuestionsData: adminQuestions?.reportedQuestionsData,
	}));

	useEffect(() => {
		dispatch(reportedQuestionsRequest());
	}, []);

	const handleCloseEditModal = () => setShowEditModal(false);
	const handleShowEditModal = (item) => {
		setShowEditModal(true);
		setViewData(item?.question);
	};
	const handleCloseViewModal = () => setShowViewModal(false);
	const handleShowViewModal = (item) => {
		setShowViewModal(true);
		setViewData(item?.question);
	};

	return (
		<div>
			{/* <div className="admin-question-loader">
					<Spinner color="white" />
				</div> */}
			<Row className="mt-5">
				<Col sm={12}>
					<h2>Reported Questions</h2>
				</Col>
			</Row>
			<Row className="mt-3">
				<div className="table-area">
					{reportedQuestionsData ? (
						<table class="table table-dark table-user-list">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Questions</th>
									<th scope="col">Hint</th>
									<th scope="col">Answers</th>
									<th scope="col">Reason</th>
									<th scope="col">Reasoning points</th>
									<th scope="col">Actions</th>
								</tr>
							</thead>

							<>
								{reportedQuestionsData &&
									reportedQuestionsData?.response?.report?.map(
										(item, index) => {
											return (
												<>
													<tbody>
														<tr>
															<th scope="row">{index + 1}</th>
															<td>{item?.question?.question}</td>
															<td>{item?.question?.hint}</td>
															<td>
																{item?.question?.answers?.map((ans, i) => {
																	return (
																		<>
																			<div>
																				{i + 1}: {ans?.answer}
																			</div>
																		</>
																	);
																})}
															</td>
															<td>{item?.reportedReason}</td>
															<td>
																<div>{item?.ambigiousQus}</div>
																<div>{item?.outOfSlybusQus}</div>
																<div>{item?.repeatedQus}</div>
															</td>
															<td>
																<div
																	className="actions"
																	onClick={() => handleShowViewModal(item)}
																>
																	<FontAwesomeIcon
																		icon={faPlus}
																		className="me-2"
																	/>
																	View
																</div>
																<div
																	className="actions mb-2"
																	onClick={() => handleShowEditModal(item)}
																>
																	<FontAwesomeIcon
																		icon={faPenToSquare}
																		className="me-2"
																	/>
																	Edit
																</div>
															</td>
														</tr>
													</tbody>
												</>
											);
										}
									)}
							</>
						</table>
					) : (
						<div className="text-center mt-2">Data Not Found</div>
					)}
				</div>
			</Row>
			<ReportedQusEditModal
				showEditModal={showEditModal}
				handleCloseEditModal={handleCloseEditModal}
				viewData={viewData}
				// topicQuizeId={topicQuizeId}
			/>

			<AdminViewModal
				title="View your Question"
				showViewModal={showViewModal}
				handleCloseViewModal={handleCloseViewModal}
				viewData={viewData}
				viewComponent={<QusViewComponent viewData={viewData} />}
			/>
		</div>
	);
};

export default ReportedQuestions;
