import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import { adminUserListRequest } from "../../../redux/reducers/duck/userDuck";

const AdminUsers = () => {
	const dispatch = useDispatch();

	const { adminUserListData } = useSelector(({ user }) => ({
		adminUserListData: user?.adminUserListData,
	}));

	useEffect(() => {
		dispatch(adminUserListRequest());
	}, [dispatch]);

	return (
		<>
			<Row className="mt-5">
				<Col sm={12}>
					<h2>Users List</h2>
				</Col>
			</Row>
			<Row className="mt-3 mx-2">
				<div className="table-area">
					<table className="table table-dark table-user-list">
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Name</th>
								<th scope="col">Email</th>
								<th scope="col">Quezzes</th>
								<th scope="col">Is School User</th>
							</tr>
						</thead>

						<tbody>
							{adminUserListData &&
								adminUserListData?.map((item, index) => {
									return (
										<tr>
											<th scope="row">{index + 1}</th>
											<td>{item?.name}</td>
											<td>{item?.email}</td>
											<td>not specified</td>
											<td>{item?.schoolUserId ? "Yes" : ""}</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			</Row>
		</>
	);
};

export default AdminUsers;
