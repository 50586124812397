import React from "react";
import LoginPage from "../../../../components/loginPage/login";

const Login = () => {
	return (
		<h1>
			<LoginPage />
		</h1>
	);
};

export default Login;
