import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
	quizzEditRequest,
	reportedQuestionsRequest,
} from "../../redux/reducers/duck/adminQuestionsDuck";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { createSchoolRequest } from "../../redux/reducers/duck/adminSchoolDuck";
import { success } from "../../utils/notifications";

const CreateSchoolModal = (props) => {
	const { showEditModal, handleCloseEditModal } = props;
	const dispatch = useDispatch();

	const [schoolName, setSchoolName] = useState();

	// Handle input changes
	const handleInputChange = (e) => {
		setSchoolName(e.target.value);
	};

	// Handle answer changes

	// Handle form submission
	const handleFormSubmit = () => {
		if (!schoolName) {
			success("Please fill field");
			return;
		}
		dispatch(createSchoolRequest({ name: schoolName }));
		handleCloseEditModal();
	};

	return (
		<>
			<Modal
				show={showEditModal}
				onHide={handleCloseEditModal}
				backdrop="static"
				centered
				aria-labelledby="contained-modal-title-vcenter"
				className="edit-modal"
			>
				<Modal.Header>
					<Modal.Title>Create School</Modal.Title>
					<button className="closeButton" onClick={handleCloseEditModal}>
						x
					</button>
				</Modal.Header>
				<Modal.Body>
					<div>
						<Form.Label>School</Form.Label>
						<Form.Control
							type="text"
							name="question"
							className="input-fields"
							value={schoolName}
							onChange={handleInputChange}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="saveChangesBtn" onClick={handleFormSubmit}>
						Save Changes
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default CreateSchoolModal;
