import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateTopicsRequest } from "../../redux/reducers/duck/adminTopicsDuck";
import { Editor } from "@tinymce/tinymce-react";

const AdminEditTextModal = ({
	showEditModal,
	handleCloseEditModal,
	viewData,
	paperId,
}) => {
	const [editTopic, setEditTopic] = useState(viewData?.name);
	const dispatch = useDispatch();
	const [content, setContent] = useState(viewData?.content);

	const handleFormSubmit = () => {
		const payload = {
			id: viewData?.id,
			name: editTopic,
			paperId: paperId,
			content: content,
		};
		dispatch(updateTopicsRequest(payload));
		handleCloseEditModal();
	};
	const editorRef = useRef(null);

	useEffect(() => {
		setEditTopic(viewData?.name);
		setContent(viewData?.content);
	}, [viewData, showEditModal]);

	const handleEditorChange = (event) => {
		setContent(event);
	};

	return (
		<Modal
			show={showEditModal}
			onHide={handleCloseEditModal}
			backdrop="static"
			centered
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			className="edit-modal"
		>
			<Modal.Header>
				<Modal.Title>Edit Topic</Modal.Title>
				<button className="closeButton" onClick={handleCloseEditModal}>
					x
				</button>
			</Modal.Header>
			<Modal.Body>
				<Form.Label>Topic</Form.Label>
				<Form.Control
					type="text"
					name="topic"
					className="input-fields"
					value={editTopic}
					onChange={(e) => {
						setEditTopic(e.target.value);
					}}
				/>

				<div>
					<Form.Label>Content</Form.Label>
					<Editor
						onInit={(evt, editor) => (editorRef.current = editor)}
						apiKey="pyzfy6adarazhjzwuyqf0p9nl8fgnubhw5vhvrng7yg5jhpb"
						init={{
							height: 350,
							menubar: false,
							// width: screenWidth > 768 ? "70%" : "92%",
						}}
						onEditorChange={handleEditorChange}
						value={content ? content : ""}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="saveChangesBtn" onClick={handleFormSubmit}>
					Save Changes
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default AdminEditTextModal;
