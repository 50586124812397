import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	nextQuestionRequest,
	quizSubmitRequest,
	quizSubmitResponse,
} from "../../../redux/reducers/duck/quizDuck";
import { error } from "../../../utils/notifications";
import { quizResultRequest } from "../../../redux/reducers/duck/resultDuck";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
	dashboardPendingQuizRequest,
	dashboardGraphRequest,
	getSubjectPrefRequest,
} from "../../../redux/reducers/duck/dashboardDuck";
import ReportQuestion from "../../../components/reportQuestionModal";
import {
	primarilyQuizeIdResponse,
	primiarlyConfirmationRequest,
	primiarlyQuizeStartResponse,
} from "../../../redux/reducers/duck/primiarlyQuizeDuck";

function QuizQuestion() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [check, setCheck] = useState(false);
	const [answer, setAnswer] = useState(null);
	const [disableOptions, setDisableOptions] = useState(false);
	const [quizData, setQuizData] = useState("");
	const [attemptCount, setAttemptCount] = useState(0);
	const [showReportModal, setShowReportModal] = useState(false);
	const [reportData, setreportData] = useState("");

	const {
		startQuizData,
		quizSubmitData,
		nextQuestionData,
		isLaoding,
		primarilyQuizeId,
	} = useSelector(({ quiz, primiarly }) => ({
		startQuizData: quiz?.startQuizData,
		quizSubmitData: quiz?.quizSubmitData,
		nextQuestionData: quiz?.nextQuestionData,
		isLaoding: quiz?.isLoading,
		primarilyQuizeId: primiarly?.primarilyQuizeId,
	}));

	const renderTooltip = (props) => <Tooltip>{props}</Tooltip>;

	useEffect(() => {
		dispatch(quizSubmitResponse({ response: null }));
		setQuizData(startQuizData?.question);
		setAttemptCount(startQuizData);
	}, [startQuizData, dispatch]);

	useEffect(() => {
		dispatch(quizSubmitResponse({ response: null }));
		setQuizData(nextQuestionData?.data?.question);
		setAttemptCount(nextQuestionData?.data);
	}, [nextQuestionData, dispatch]);

	// handle check question
	const handleCheckClick = () => {
		if (!answer) {
			error("Please select an answer");
			return;
		}
		const requestData = {
			questionId: quizData?.id,
			answerId: answer?.id,
			quizId: quizData?.quizId,
		};
		dispatch(quizSubmitRequest(requestData));
		setCheck(true);
		setDisableOptions(true);
	};

	// handle next question
	const handleNextClick = () => {
		if (primarilyQuizeId) {
			const quiz_id = primarilyQuizeId;
			dispatch(nextQuestionRequest(quiz_id));
		} else {
			const quiz_id = startQuizData?.question?.quizId;
			dispatch(nextQuestionRequest(quiz_id));
		}
		setAnswer("");
		setDisableOptions(false);
		setCheck(false);
	};

	const handleAnswerChange = (item) => {
		if (!disableOptions) {
			setCheck(false);
			setAnswer(item);
		}
	};
	// handle finish quizz
	const handleResult = () => {
		if (startQuizData && !primarilyQuizeId) {
			const quiz_id = startQuizData?.question?.quizId;
			dispatch(quizResultRequest(quiz_id));
			navigate("/quiz/results");
		} else if (primarilyQuizeId) {
			const quiz_id = primarilyQuizeId;
			dispatch(quizResultRequest(quiz_id));
			dispatch(primarilyQuizeIdResponse(null));
			navigate("/dashboard");
		}
	};

	// handle complete quizz
	const handleCompletQuiz = (data) => {
		if (startQuizData && !primarilyQuizeId) {
			const quizID = startQuizData?.question?.quizId;
			const topicID = startQuizData?.topicId;
			const subjectID = startQuizData?.subjectobj?.id;
			dispatch(quizResultRequest(quizID));
			dispatch(dashboardGraphRequest(topicID));
			dispatch(dashboardPendingQuizRequest(subjectID));
		} else if (primarilyQuizeId) {
			const quiz_id = primarilyQuizeId;
			dispatch(quizResultRequest(quiz_id));
			dispatch(primarilyQuizeIdResponse(null));
		}
		navigate("/dashboard");
	};

	const attemptedCount = attemptCount?.attemptsCount;
	const totalCount = attemptCount?.questionCount;

	// const totalCount = 20;

	// modal handles
	const handleCloseReportModal = () => setShowReportModal(false);
	const handleShowReportModal = (item) => {
		setShowReportModal(true);
		setreportData(item);
	};

	return (
		<React.Fragment>
			<div className="quizQuestion">
				{quizData && quizData ? (
					<>
						<div className="heading">
							{!primarilyQuizeId && (
								<h3>
									{startQuizData?.subjectobj?.name}: {startQuizData?.topicName}
								</h3>
							)}
						</div>
						<div className={`card ${primarilyQuizeId && "card-width"}`}>
							<p>
								Question: {attemptedCount + 1}
								{/* of {totalCount} */}
							</p>
							<div className="question">
								<p>{quizData?.question}</p>
							</div>
							<div className="hint">
								<OverlayTrigger
									placement="left"
									overlay={renderTooltip(quizData?.hint)}
								>
									<Button>
										<img src="/images/hint.svg" alt="hint" /> Hint
									</Button>
								</OverlayTrigger>
							</div>
							<div
								className={`${
									quizSubmitData?.statusCode === 200 ||
									quizSubmitData?.statusCode === 201
										? quizSubmitData?.data?.isCorrect === true
											? "success-active answers"
											: "danger-active answers"
										: "answers"
								}`}
							>
								<Form>
									<div>
										{quizData?.answers?.map((item, index) => {
											return (
												<Form.Check
													label={item?.answer}
													name="group"
													type="radio"
													id={index + 1}
													value={item?.answer}
													onChange={() => handleAnswerChange(item)}
													checked={answer === item}
													disabled={disableOptions}
												/>
											);
										})}
									</div>
								</Form>
							</div>
							<div className="quizz-buttons">
								{attemptedCount >= 10 && !primarilyQuizeId && (
									<div className="question-handle-btns" onClick={handleResult}>
										<Link to="/quiz/results">Finish</Link>
									</div>
								)}

								{/* report question  */}
								<div
									className="question-handle-btns"
									onClick={() => handleShowReportModal(quizData)}
								>
									<Link>Report</Link>
								</div>

								<div>
									{check ? (
										<div>
											{attemptedCount + 1 === totalCount ? (
												<div
													className="question-handle-btns"
													onClick={() => handleCompletQuiz(quizData)}
												>
													<Link>Complete</Link>
												</div>
											) : (
												<div
													className="question-handle-btns"
													onClick={handleNextClick}
												>
													{isLaoding ? (
														<Link>loading...</Link>
													) : (
														<Link>Next</Link>
													)}
												</div>
											)}
										</div>
									) : (
										<div
											className="question-handle-btns"
											onClick={handleCheckClick}
										>
											{isLaoding ? <Link>loading...</Link> : <Link>Check</Link>}
										</div>
									)}
								</div>
							</div>
							<div className="answers">
								<Form>
									{(quizSubmitData?.statusCode === 200 ||
										quizSubmitData?.statusCode === 201) && (
										<div className="hint-message">
											<p className="title">Method:</p>
											<p>{quizData?.explaination}</p>
										</div>
									)}
								</Form>
							</div>
						</div>
					</>
				) : (
					<p className="text-center">No Question exist</p>
				)}
			</div>

			<ReportQuestion
				showReportModal={showReportModal}
				handleCloseReportModal={handleCloseReportModal}
				reportData={reportData}
			/>
		</React.Fragment>
	);
}

export default QuizQuestion;
