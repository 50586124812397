import Axios from "./axios";
import urls from "./url";

// topics api
export const preferneceApi = async (id) => {
	let token = localStorage.getItem("token");
	try {
		return await Axios.get(`${urls?.primiarly?.prefernece}/${id}`, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const primiarlyConfirmationApi = async (id) => {
	let token = localStorage.getItem("token");
	try {
		return await Axios.get(`${urls?.primiarly?.confirmation}`, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};

export const primiarlyQuizeApi = async (id) => {
	let token = localStorage.getItem("token");
	try {
		return await Axios.get(`${urls?.primiarly?.quize}`, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
	} catch (err) {
		return err;
	}
};
