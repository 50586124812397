import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	allTopicsData: null,
	singleTopicData: null,
	updateTopicData: null,
	isTopicsLoading: null,
};

export const adminTopicsSlice = createSlice({
	name: "adminTopics",
	initialState: INITIAL_STATE,
	reducers: {
		// response reducers
		// request reducers
		allTopicsRequest: (state) => state,
		updateTopicsRequest: (state) => state,
		singleTopicRequest: (state) => state,
		deleteTopicRequest: (state) => state,
		allTopicsDataResponse(state, { payload }) {
			return {
				...state,
				allTopicsData: payload?.response,
			};
		},
		topicByIdResponse(state, { payload }) {
			return {
				...state,
				singleTopicData: payload?.response,
			};
		},
		updateTopicResponse(state, { payload }) {
			return {
				...state,
				updateTopicData: payload?.response,
			};
		},
		adminTopicsLoading(state, { payload }) {
			return {
				...state,
				isTopicsLoading: payload,
			};
		},
	},
});

export const {
	topicByIdResponse,
	singleTopicRequest,
	allTopicsRequest,
	updateTopicsRequest,
	deleteTopicRequest,
	allTopicsDataResponse,
	updateTopicResponse,
	adminTopicsLoading,
} = adminTopicsSlice.actions;

export default adminTopicsSlice.reducer;
