import React, { useEffect } from "react";
import AppRoutes from "./Routes/index";
import { useNavigate } from "react-router";

function App() {
	localStorage.setItem("isPrimarily", "ee");

	return <AppRoutes />;
}

export default App;
