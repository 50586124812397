import { put, call, takeLatest } from "redux-saga/effects";
import * as actions from "../../reducers/duck/adminQuestionsDuck";
import {
	allCombinationsApi,
	papersByCombinationsApi,
	topicsByPapersApi,
	quizzByTopicsApi,
	quizzEditApi,
	reportedQuestionsApi,
} from "../../../api/adminQuestionsApi";
import { success, error } from "../../../utils/notifications";
import { adminTopicsLoading } from "../../reducers/duck/adminTopicsDuck";

// all-combinations saga
export function* allCombinationsSaga() {
	try {
		const response = yield call(allCombinationsApi);
		if (response?.data) {
			yield put(
				actions.allCombinationsResponse({ response: response?.data?.subjects })
			);
		}
	} catch (error) {
	} finally {
	}
}

// papers-by-combinations saga
export function* papersByCombinationsSaga({ payload }) {
	try {
		const response = yield call(papersByCombinationsApi, payload);
		if (response?.data) {
			yield put(
				actions.papersByCombinationsResponse({
					response: response?.data?.paper,
				})
			);
		}
	} catch (error) {
	} finally {
	}
}

// topics-by-papers saga
export function* topicsByPapersSaga({ payload }) {
	try {
		yield put(adminTopicsLoading(true));
		const response = yield call(topicsByPapersApi, payload);
		if (response?.data) {
			yield put(
				actions.topicsByPapersResponse({ response: response?.data?.topic })
			);
		}
	} catch (error) {
	} finally {
		yield put(adminTopicsLoading(false));
	}
}

//  quizz-by-topics saga
export function* quizzByTopicsSaga({ payload }) {
	try {
		yield put(actions.adminQuestionsLoading(true));
		const response = yield call(quizzByTopicsApi, payload);
		if (response?.data) {
			yield put(
				actions.quizzByTopicsResponse({ response: response?.data?.quiz })
			);
		}
	} catch (error) {
	} finally {
		yield put(actions.adminQuestionsLoading(false));
	}
}

//  quizz-by-topics saga
export function* quizzEditSaga({ payload }) {
	try {
		const response = yield call(quizzEditApi, payload?.data);
		if (response?.data) {
			yield put(actions.quizzEditResponse({ response: response?.data?.data }));
			yield put(actions.quizzByTopicsRequest(payload?.topicQuizeId));
		}
		success("Quizz Updated Successfully");
	} catch (error) {
	} finally {
	}
}

// reported-questions saga
export function* reportedQuestionsSaga() {
	try {
		const response = yield call(reportedQuestionsApi);
		if (response?.data) {
			yield put(
				actions.reportedQuestionsResponse({ response: response?.data })
			);
		}
	} catch (error) {
	} finally {
	}
}

export function* watchAdminQuestionsSagas() {
	yield takeLatest(actions.allCombinationsRequest.type, allCombinationsSaga);
	yield takeLatest(actions.topicsByPapersRequest.type, topicsByPapersSaga);
	yield takeLatest(actions.quizzByTopicsRequest.type, quizzByTopicsSaga);
	yield takeLatest(
		actions.papersByCombinationsRequest.type,
		papersByCombinationsSaga
	);
	yield takeLatest(actions.quizzEditRequest.type, quizzEditSaga);
	yield takeLatest(
		actions.reportedQuestionsRequest.type,
		reportedQuestionsSaga
	);
}
