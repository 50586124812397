import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import { loginRequest } from "../../redux/reducers/duck/authDuck";
import { ButtonLoader } from "../Helper/loader";
import { getEmailErrors, getEmailValidator } from "../../utils/authValidator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const LoginPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isEyeIconClick, setIsEyeIconClick] = useState(false);

	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [errors, setErrors] = useState({});
	const [isLoginSubmitted, setIsLoginSubmitted] = useState(false);
	const [isReCaptchaValid, setIsReCaptchaValid] = useState(false);

	const { login, isLoading } = useSelector(({ auth }) => ({
		login: auth?.loginRes,
		isLoading: auth?.isLoading,
	}));

	const findErrors = () => {
		const newErrors = {};
		if (!getEmailValidator(email)) newErrors.email = getEmailErrors();
		if (!password) newErrors.password = ["Password is required."];
		return newErrors;
	};

	const handleLogin = () => {
		const newErrors = findErrors();
		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
		} else {
			setIsLoginSubmitted(true);
		}
	};

	useEffect(() => {
		if (isReCaptchaValid) {
			const requestData = {
				email: email,
				password: password,
			};
			dispatch(loginRequest(requestData));
			clearState();
		}
	}, [isReCaptchaValid]);

	useEffect(() => {
		if (!isLoading) {
			setIsReCaptchaValid(false);
			setIsLoginSubmitted(false);
		}
	}, [isLoading]);

	function onChange(value) {
		if (value) {
			setIsReCaptchaValid(true);
		} else {
			setIsReCaptchaValid(false);
		}
	}

	useEffect(() => {
		if (login) {
			window.location.href = "/dashboard";
		}
	}, [login, navigate]);

	const clearState = () => {
		setEmail("");
		setPassword("");
	};

	return (
		<>
			<div className="main-container">
				<Container className="login-container">
					<div className="form">
						<img
							src="/images/logo.png"
							width={100}
							alt="logo"
							className="logo-img"
						/>
						<h1>MyScienceLand</h1>
						<p>Sign Into MyScienceLand</p>
						<div className="form-group">
							<label>Email</label>
							<input
								type="email"
								value={email}
								placeholder="Enter email"
								required
								onChange={(e) => setEmail(e?.target?.value)}
							/>
							<p className="errmsg">
								{errors.email?.map((err, index) => {
									if (index === errors.email.length) return err;
									else return err + "\n";
								})}
							</p>
						</div>
						<div className="form-group">
							<label>Password</label>
							<div className="password-input-container">
								<input
									type={isEyeIconClick ? "text" : "password"} // Set input type dynamically
									value={password}
									placeholder="Password"
									required
									onChange={(e) => setPassword(e.target.value)}
								/>
								<span
									className="eye-icon"
									onClick={() => setIsEyeIconClick(!isEyeIconClick)}
								>
									{isEyeIconClick ? (
										<FontAwesomeIcon icon={faEye} />
									) : (
										<FontAwesomeIcon icon={faEyeSlash} />
									)}
								</span>
							</div>
							<p className="errmsg">
								{errors.password?.map((err, index) => {
									if (index === errors.password.length) return err;
									else return err + "\n";
								})}
							</p>
						</div>

						<div className="checkbox-container">
							<label className="checkbox">
								<input type="checkbox" className="checkbox-input" />
								<span className="checkmark"></span>
								<span className="">Remember Me</span>
							</label>
							<p className="">Forgot password?</p>
						</div>
						{isLoading ? (
							<div className="loader-button">
								<div className="button-container">
									<ButtonLoader />
									<button type="submit">Login</button>
								</div>
							</div>
						) : (
							<div className="button">
								{isLoginSubmitted && (
									<div className="recaptchParent">
										<ReCAPTCHA
											style={{
												display: "inline-block",
												color: "white",
											}}
											theme="dark"
											sitekey={process.env.REACT_APP_ReactCaptcha}
											onChange={onChange}
										/>
									</div>
								)}
								<button type="submit" onClick={() => handleLogin()}>
									Login
								</button>
								<div className="signup">
									<p>To join MyScienceLand, please</p>
									<a href="/signup">
										<button className="mt-2" type="submit">
											Sign Up
										</button>
									</a>
								</div>
							</div>
						)}
					</div>
				</Container>
			</div>
		</>
	);
};

export default LoginPage;
