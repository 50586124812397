export const allSubjectNames = ["Chemistry", "Physics", "Biology"];
export const continueQuizes = {
	statusCode: 200,
	message: "User's continued Quiz fetched successfully",
	quizzes: [
		{
			id: "4b2b01e0-29a2-4f8e-9938-bd8b7ba4224a",
			name: "Edexcel",
			difficultyLevel: "1900",
			topicId: "37d59111-8384-11ee-ab22-b88a6056a3a1",
			createdAt: "2023-11-17T14:28:09.272Z",
			updatedAt: "2023-11-17T14:28:09.272Z",
			topic: {
				id: "37d59111-8384-11ee-ab22-b88a6056a3a1",
				name: "Atomic Structure and the Periodic Table",
				paperId: "379e5d48-8384-11ee-ab22-b88a6056a3a1",
				content: null,
				createdAt: "2023-11-15T06:57:19.072Z",
				updatedAt: "2023-11-15T06:57:19.072Z",
			},
		},
	],
	weakTags: ["s, p, d-block Elements", "Periodic Trends: Ionisation Energy"],
};

export const sujectTopics = {
	statusCode: 200,
	message: "Topic fetched successfully",
	data: {
		topics: [
			{
				id: "0e94bfaa-848c-11ee-b4c0-b88a6056a3a1",
				name: "Key concepts in chemistry ",
			},
			{
				id: "0e94d20c-848c-11ee-b4c0-b88a6056a3a1",
				name: "States of matter and mixture",
			},
			{
				id: "0e953635-848c-11ee-b4c0-b88a6056a3a1",
				name: "Extracting metals and equilibria",
			},
			{
				id: "0e955d4c-848c-11ee-b4c0-b88a6056a3a1",
				name: "Separate chemistry 1",
			},
			{
				id: "0e956d2c-848c-11ee-b4c0-b88a6056a3a1",
				name: "Groups in the periodic table",
			},
			{
				id: "0e9591b7-848c-11ee-b4c0-b88a6056a3a1",
				name: "Rates of reaction and energy changes",
			},
			{
				id: "0e959b8f-848c-11ee-b4c0-b88a6056a3a1",
				name: "Fuels and Earth science",
			},
			{
				id: "0e95a2da-848c-11ee-b4c0-b88a6056a3a1",
				name: "Separate chemistry 2",
			},
			{
				id: "0e95a917-848c-11ee-b4c0-b88a6056a3a1",
				name: "Groups in the periodic table",
			},
			{
				id: "0e95aef6-848c-11ee-b4c0-b88a6056a3a1",
				name: "Rates of reaction and energy changes",
			},
			{
				id: "0e95b8cb-848c-11ee-b4c0-b88a6056a3a1",
				name: "Fuels and Earth science",
			},
			{
				id: "0e95c0fc-848c-11ee-b4c0-b88a6056a3a1",
				name: "Separate chemistry 2",
			},
			{
				id: "0ebc6864-848c-11ee-b4c0-b88a6056a3a1",
				name: "Atomic structure and the periodic table",
			},
			{
				id: "0ebc7272-848c-11ee-b4c0-b88a6056a3a1",
				name: "Bonding, structure, and the properties of matter",
			},
			{
				id: "0ebcc201-848c-11ee-b4c0-b88a6056a3a1",
				name: "Quantitative chemistry",
			},
			{
				id: "0ebcd39d-848c-11ee-b4c0-b88a6056a3a1",
				name: "Chemical changes",
			},
			{
				id: "0ebcf16c-848c-11ee-b4c0-b88a6056a3a1",
				name: "Energy changes",
			},
			{
				id: "0ebcfb7d-848c-11ee-b4c0-b88a6056a3a1",
				name: "The rate and extent of chemical change",
			},
			{
				id: "0ebd02bb-848c-11ee-b4c0-b88a6056a3a1",
				name: "Organic chemistry",
			},
			{
				id: "0ebd0875-848c-11ee-b4c0-b88a6056a3a1",
				name: "Chemical analysis",
			},
			{
				id: "0ebd0e69-848c-11ee-b4c0-b88a6056a3a1",
				name: "Chemistry of the atmosphere",
			},
			{
				id: "0ebd1740-848c-11ee-b4c0-b88a6056a3a1",
				name: "Using resources",
			},
			{
				id: "0ee14bfb-848c-11ee-b4c0-b88a6056a3a1",
				name: "Particles",
			},
			{
				id: "0ee15868-848c-11ee-b4c0-b88a6056a3a1",
				name: "Elements, compounds and mixtures",
			},
			{
				id: "0ee18768-848c-11ee-b4c0-b88a6056a3a1",
				name: "Chemical reactions",
			},
			{
				id: "0ee24383-848c-11ee-b4c0-b88a6056a3a1",
				name: "Predicting and identifying reactions and products",
			},
			{
				id: "0ee26fe3-848c-11ee-b4c0-b88a6056a3a1",
				name: "Monitoring and controlling chemical reactions",
			},
			{
				id: "0ee27c3e-848c-11ee-b4c0-b88a6056a3a1",
				name: "Global challenges",
			},
			{
				id: "0ee287ba-848c-11ee-b4c0-b88a6056a3a1",
				name: "Predicting and identifying reactions and products",
			},
			{
				id: "0ee28f75-848c-11ee-b4c0-b88a6056a3a1",
				name: "Monitoring and controlling chemical reactions",
			},
			{
				id: "0ee296cb-848c-11ee-b4c0-b88a6056a3a1",
				name: "Global challenges",
			},
			{
				id: "37d59111-8384-11ee-ab22-b88a6056a3a1",
				name: "Atomic Structure and the Periodic Table",
			},
			{
				id: "37d62305-8384-11ee-ab22-b88a6056a3a1",
				name: "Bonding and Structure",
			},
			{
				id: "37d7a97c-8384-11ee-ab22-b88a6056a3a1",
				name: "Redox I",
			},
			{
				id: "37d8a1e5-8384-11ee-ab22-b88a6056a3a1",
				name: "Inorganic Chemistry and the Periodic Table",
			},
			{
				id: "37d930b6-8384-11ee-ab22-b88a6056a3a1",
				name: "Formulae, Equations and Amounts of Substance",
			},
			{
				id: "37dab93a-8384-11ee-ab22-b88a6056a3a1",
				name: "Energetics",
			},
			{
				id: "37dbc9d3-8384-11ee-ab22-b88a6056a3a1",
				name: "Equilibrium",
			},
			{
				id: "37dc56c8-8384-11ee-ab22-b88a6056a3a1",
				name: "Acid-base Equilibria",
			},
			{
				id: "37dcea65-8384-11ee-ab22-b88a6056a3a1",
				name: "Energetics II",
			},
			{
				id: "37dd9b10-8384-11ee-ab22-b88a6056a3a1",
				name: "Redox II",
			},
			{
				id: "37de4e00-8384-11ee-ab22-b88a6056a3a1",
				name: "Transition Metals",
			},
			{
				id: "37d9beb8-8384-11ee-ab22-b88a6056a3a1",
				name: "Organic Chemistry",
			},
			{
				id: "37da4642-8384-11ee-ab22-b88a6056a3a1",
				name: "Modern Analytical Techniques",
			},
			{
				id: "37db4224-8384-11ee-ab22-b88a6056a3a1",
				name: "Kinetics",
			},
			{
				id: "37df0d99-8384-11ee-ab22-b88a6056a3a1",
				name: " Kinetics II",
			},
			{
				id: "37dfc0d5-8384-11ee-ab22-b88a6056a3a1",
				name: "Organic Chemistry II",
			},
			{
				id: "37e0756d-8384-11ee-ab22-b88a6056a3a1",
				name: "Organic Chemistry III",
			},
			{
				id: "37e15a5a-8384-11ee-ab22-b88a6056a3a1",
				name: "Organic Synthesis",
			},
			{
				id: "37e23c72-8384-11ee-ab22-b88a6056a3a1",
				name: "Bonding and Structure",
			},
			{
				id: "37e2e906-8384-11ee-ab22-b88a6056a3a1",
				name: "Redox I",
			},
			{
				id: "37e3a4e5-8384-11ee-ab22-b88a6056a3a1",
				name: "Formulae, Equations and Amounts of Substance",
			},
			{
				id: "0e039c9f-848c-11ee-b4c0-b88a6056a3a1",
				name: "Amount of substance",
			},
			{
				id: "0e062ce7-848c-11ee-b4c0-b88a6056a3a1",
				name: "Bonding",
			},
			{
				id: "0e0674e2-848c-11ee-b4c0-b88a6056a3a1",
				name: "Energetics",
			},
			{
				id: "0e06cdc1-848c-11ee-b4c0-b88a6056a3a1",
				name: "Chemical equilibria, Le Chatelier's principle and Kc",
			},
			{
				id: "0e06d72f-848c-11ee-b4c0-b88a6056a3a1",
				name: "Oxidation, reduction and redox equations",
			},
			{
				id: "0e06f10d-848c-11ee-b4c0-b88a6056a3a1",
				name: "Thermodynamics",
			},
			{
				id: "0e06faff-848c-11ee-b4c0-b88a6056a3a1",
				name: "Rate equations",
			},
			{
				id: "0e0702ed-848c-11ee-b4c0-b88a6056a3a1",
				name: "Equilibrium constant Kp for homogeneous systems",
			},
			{
				id: "0e070931-848c-11ee-b4c0-b88a6056a3a1",
				name: "Acids and bases",
			},
			{
				id: "0e0710fc-848c-11ee-b4c0-b88a6056a3a1",
				name: "Periodicity",
			},
			{
				id: "0e0716df-848c-11ee-b4c0-b88a6056a3a1",
				name: "Group 2, the alkaline earth metals",
			},
			{
				id: "0e071e01-848c-11ee-b4c0-b88a6056a3a1",
				name: "Group 7(17), the halogens",
			},
			{
				id: "0e072579-848c-11ee-b4c0-b88a6056a3a1",
				name: "Properties of Period 3 elements and their oxides",
			},
			{
				id: "0e072cc7-848c-11ee-b4c0-b88a6056a3a1",
				name: "Transition metals",
			},
			{
				id: "0e07346e-848c-11ee-b4c0-b88a6056a3a1",
				name: "Reactions of ions in aqueous solution",
			},
			{
				id: "0e06b1a6-848c-11ee-b4c0-b88a6056a3a1",
				name: "Kinetics",
			},
			{
				id: "0e073d36-848c-11ee-b4c0-b88a6056a3a1",
				name: "Introduction to organic chemistry",
			},
			{
				id: "0e074403-848c-11ee-b4c0-b88a6056a3a1",
				name: "Alkanes",
			},
			{
				id: "0e074b69-848c-11ee-b4c0-b88a6056a3a1",
				name: "Halogenoalkanes",
			},
			{
				id: "0e0754fa-848c-11ee-b4c0-b88a6056a3a1",
				name: "Alkenes",
			},
			{
				id: "0e075e1a-848c-11ee-b4c0-b88a6056a3a1",
				name: "Alcohols",
			},
			{
				id: "0e076712-848c-11ee-b4c0-b88a6056a3a1",
				name: "Organic analysis",
			},
			{
				id: "0e077000-848c-11ee-b4c0-b88a6056a3a1",
				name: "Optical isomerism",
			},
			{
				id: "0e077a44-848c-11ee-b4c0-b88a6056a3a1",
				name: "Aldehydes and ketones",
			},
			{
				id: "0e0784eb-848c-11ee-b4c0-b88a6056a3a1",
				name: "Carboxylic acids and derivatives",
			},
			{
				id: "0e078eb6-848c-11ee-b4c0-b88a6056a3a1",
				name: "Aromatic chemistry",
			},
			{
				id: "0e0797f5-848c-11ee-b4c0-b88a6056a3a1",
				name: "Amines",
			},
			{
				id: "0e07a07a-848c-11ee-b4c0-b88a6056a3a1",
				name: "Polymers",
			},
			{
				id: "0e07a7a0-848c-11ee-b4c0-b88a6056a3a1",
				name: "Amino acids, proteins and DNA",
			},
			{
				id: "0e07b0e4-848c-11ee-b4c0-b88a6056a3a1",
				name: "Organic synthesis",
			},
			{
				id: "0e07b6df-848c-11ee-b4c0-b88a6056a3a1",
				name: "Nuclear magnetic resonance spectroscopy",
			},
			{
				id: "0e07bdd5-848c-11ee-b4c0-b88a6056a3a1",
				name: "Chromatography",
			},
			{
				id: "0e07d089-848c-11ee-b4c0-b88a6056a3a1",
				name: "Amount of substance",
			},
			{
				id: "0e07ecaf-848c-11ee-b4c0-b88a6056a3a1",
				name: "Bonding",
			},
			{
				id: "0e07fac5-848c-11ee-b4c0-b88a6056a3a1",
				name: "Energetics",
			},
			{
				id: "0e0802fd-848c-11ee-b4c0-b88a6056a3a1",
				name: "Kinetics",
			},
			{
				id: "0e080b1f-848c-11ee-b4c0-b88a6056a3a1",
				name: "Chemical equilibria, Le Chatelier's principle and Kc",
			},
			{
				id: "0e081389-848c-11ee-b4c0-b88a6056a3a1",
				name: "Introduction to organic chemistry",
			},
			{
				id: "0e081a0b-848c-11ee-b4c0-b88a6056a3a1",
				name: "Alkanes",
			},
			{
				id: "0e0823a9-848c-11ee-b4c0-b88a6056a3a1",
				name: "Halogenoalkanes",
			},
			{
				id: "0e082d5a-848c-11ee-b4c0-b88a6056a3a1",
				name: "Alkenes",
			},
			{
				id: "0e0837fe-848c-11ee-b4c0-b88a6056a3a1",
				name: "Alcohols",
			},
			{
				id: "0e0840f9-848c-11ee-b4c0-b88a6056a3a1",
				name: "Organic analysis",
			},
			{
				id: "0e084d58-848c-11ee-b4c0-b88a6056a3a1",
				name: "Optical isomerism",
			},
			{
				id: "0e085594-848c-11ee-b4c0-b88a6056a3a1",
				name: "Aldehydes and ketones",
			},
			{
				id: "0e085d07-848c-11ee-b4c0-b88a6056a3a1",
				name: "Carboxylic acids and derivatives",
			},
		],
	},
};

export const dataSeries = [
	[
		{
			date: "2014-04-29",
			value: 79470783,
		},
		{
			date: "2014-04-30",
			value: 80170783,
		},
	],
	[
		{
			date: "2014-01-01",
			value: 150000000,
		},
		{
			date: "2014-01-02",
			value: 160379978,
		},
		{
			date: "2014-01-03",
			value: 170493749,
		},
		{
			date: "2014-01-04",
			value: 160785250,
		},
		{
			date: "2014-01-05",
			value: 167391904,
		},
		{
			date: "2014-01-06",
			value: 161576838,
		},
		{
			date: "2014-01-07",
			value: 161413854,
		},
		{
			date: "2014-01-08",
			value: 152177211,
		},
		{
			date: "2014-01-09",
			value: 140762210,
		},
		{
			date: "2014-01-10",
			value: 144381072,
		},
		{
			date: "2014-01-11",
			value: 154352310,
		},
		{
			date: "2014-01-12",
			value: 165531790,
		},
		{
			date: "2014-01-13",
			value: 175748881,
		},
		{
			date: "2014-01-14",
			value: 187064037,
		},
		{
			date: "2014-01-15",
			value: 197520685,
		},
		{
			date: "2014-01-16",
			value: 210176418,
		},
		{
			date: "2014-01-17",
			value: 196122924,
		},
		{
			date: "2014-01-18",
			value: 207337480,
		},
		{
			date: "2014-01-19",
			value: 200258882,
		},
		{
			date: "2014-01-20",
			value: 186829538,
		},
		{
			date: "2014-01-21",
			value: 192456897,
		},
		{
			date: "2014-01-22",
			value: 204299711,
		},
		{
			date: "2014-01-23",
			value: 192759017,
		},
		{
			date: "2014-01-24",
			value: 203596183,
		},
		{
			date: "2014-01-25",
			value: 208107346,
		},
		{
			date: "2014-01-26",
			value: 196359852,
		},
		{
			date: "2014-01-27",
			value: 192570783,
		},
		{
			date: "2014-01-28",
			value: 177967768,
		},
		{
			date: "2014-01-29",
			value: 190632803,
		},
		{
			date: "2014-01-30",
			value: 203725316,
		},
		{
			date: "2014-01-31",
			value: 218226177,
		},
		{
			date: "2014-02-01",
			value: 210698669,
		},
		{
			date: "2014-02-02",
			value: 217640656,
		},
		{
			date: "2014-02-03",
			value: 216142362,
		},
		{
			date: "2014-02-04",
			value: 201410971,
		},
		{
			date: "2014-02-05",
			value: 196704289,
		},
		{
			date: "2014-02-06",
			value: 190436945,
		},
		{
			date: "2014-02-07",
			value: 178891686,
		},
		{
			date: "2014-02-08",
			value: 171613962,
		},
		{
			date: "2014-02-09",
			value: 157579773,
		},
		{
			date: "2014-02-10",
			value: 158677098,
		},
		{
			date: "2014-02-11",
			value: 147129977,
		},
		{
			date: "2014-02-12",
			value: 151561876,
		},
		{
			date: "2014-02-13",
			value: 151627421,
		},
		{
			date: "2014-02-14",
			value: 143543872,
		},
		{
			date: "2014-02-15",
			value: 136581057,
		},
		{
			date: "2014-02-16",
			value: 135560715,
		},
		{
			date: "2014-02-17",
			value: 122625263,
		},
		{
			date: "2014-02-18",
			value: 112091484,
		},
		{
			date: "2014-02-19",
			value: 98810329,
		},
		{
			date: "2014-02-20",
			value: 99882912,
		},
		{
			date: "2014-02-21",
			value: 94943095,
		},
		{
			date: "2014-02-22",
			value: 104875743,
		},
		{
			date: "2014-02-23",
			value: 116383678,
		},
		{
			date: "2014-02-24",
			value: 125028841,
		},
		{
			date: "2014-02-25",
			value: 123967310,
		},
		{
			date: "2014-02-26",
			value: 133167029,
		},
		{
			date: "2014-02-27",
			value: 128577263,
		},
		{
			date: "2014-02-28",
			value: 115836969,
		},
		{
			date: "2014-03-01",
			value: 119264529,
		},
		{
			date: "2014-03-02",
			value: 109363374,
		},
		{
			date: "2014-03-03",
			value: 113985628,
		},
		{
			date: "2014-03-04",
			value: 114650999,
		},
		{
			date: "2014-03-05",
			value: 110866108,
		},
		{
			date: "2014-03-06",
			value: 96473454,
		},
		{
			date: "2014-03-07",
			value: 104075886,
		},
		{
			date: "2014-03-08",
			value: 103568384,
		},
		{
			date: "2014-03-09",
			value: 101534883,
		},
		{
			date: "2014-03-10",
			value: 115825447,
		},
		{
			date: "2014-03-11",
			value: 126133916,
		},
		{
			date: "2014-03-12",
			value: 116502109,
		},
		{
			date: "2014-03-13",
			value: 130169411,
		},
		{
			date: "2014-03-14",
			value: 124296886,
		},
		{
			date: "2014-03-15",
			value: 126347399,
		},
		{
			date: "2014-03-16",
			value: 131483669,
		},
		{
			date: "2014-03-17",
			value: 142811333,
		},
		{
			date: "2014-03-18",
			value: 129675396,
		},
		{
			date: "2014-03-19",
			value: 115514483,
		},
		{
			date: "2014-03-20",
			value: 117630630,
		},
		{
			date: "2014-03-21",
			value: 122340239,
		},
		{
			date: "2014-03-22",
			value: 132349091,
		},
		{
			date: "2014-03-23",
			value: 125613305,
		},
		{
			date: "2014-03-24",
			value: 135592466,
		},
		{
			date: "2014-03-25",
			value: 123408762,
		},
		{
			date: "2014-03-26",
			value: 111991454,
		},
		{
			date: "2014-03-27",
			value: 116123955,
		},
		{
			date: "2014-03-28",
			value: 112817214,
		},
		{
			date: "2014-03-29",
			value: 113029590,
		},
		{
			date: "2014-03-30",
			value: 108753398,
		},
		{
			date: "2014-03-31",
			value: 99383763,
		},
		{
			date: "2014-04-01",
			value: 100151737,
		},
		{
			date: "2014-04-02",
			value: 94985209,
		},
		{
			date: "2014-04-03",
			value: 82913669,
		},
		{
			date: "2014-04-04",
			value: 78748268,
		},
		{
			date: "2014-04-05",
			value: 63829135,
		},
		{
			date: "2014-04-06",
			value: 78694727,
		},
		{
			date: "2014-04-07",
			value: 80868994,
		},
		{
			date: "2014-04-08",
			value: 93799013,
		},
		{
			date: "2014-04-09",
			value: 99042416,
		},
		{
			date: "2014-04-10",
			value: 97298692,
		},
		{
			date: "2014-04-11",
			value: 83353499,
		},
		{
			date: "2014-04-12",
			value: 71248129,
		},
		{
			date: "2014-04-13",
			value: 75253744,
		},
		{
			date: "2014-04-14",
			value: 68976648,
		},
		{
			date: "2014-04-15",
			value: 71002284,
		},
		{
			date: "2014-04-16",
			value: 75052401,
		},
		{
			date: "2014-04-17",
			value: 83894030,
		},
		{
			date: "2014-04-18",
			value: 90236528,
		},
		{
			date: "2014-04-19",
			value: 99739114,
		},
		{
			date: "2014-04-20",
			value: 96407136,
		},
		{
			date: "2014-04-21",
			value: 108323177,
		},
		{
			date: "2014-04-22",
			value: 101578914,
		},
		{
			date: "2014-04-23",
			value: 115877608,
		},
		{
			date: "2014-04-24",
			value: 112088857,
		},
		{
			date: "2014-04-25",
			value: 112071353,
		},
		{
			date: "2014-04-26",
			value: 101790062,
		},
		{
			date: "2014-04-27",
			value: 115003761,
		},
		{
			date: "2014-04-28",
			value: 120457727,
		},
		{
			date: "2014-04-29",
			value: 118253926,
		},
		{
			date: "2014-04-30",
			value: 117956992,
		},
	],
];
