import { put, call, takeLatest } from "redux-saga/effects";
import {
	allTopicsApi,
	topicById,
	topicDeleteById,
	updateTopic,
} from "../../../api/adminTopicsApi";
import {
	adminTopicsLoading,
	allTopicsDataResponse,
	allTopicsRequest,
	deleteTopicRequest,
	singleTopicRequest,
	topicByIdResponse,
	topicEditDataResponse,
	updateTopicResponse,
	updateTopicsRequest,
} from "../../reducers/duck/adminTopicsDuck";
import { topicsByPapersRequest } from "../../reducers/duck/adminQuestionsDuck";
import { success } from "../../../utils/notifications";

// all-topics saga
export function* allTopicsSaga() {
	try {
		const response = yield call(allTopicsApi);
		if (response?.data) {
			yield put(allTopicsDataResponse({ response: response?.data?.topics }));
		}
	} catch (error) {
	} finally {
	}
}

export function* getSingleTopic({ payload }) {
	try {
		const response = yield call(topicById, payload);
		if (response?.data) {
			yield put(topicByIdResponse({ response: response?.data }));
		}
	} catch (error) {
	} finally {
	}
}

export function* updateTopicSaga({ payload }) {
	try {
		yield put(adminTopicsLoading(true));

		const response = yield call(updateTopic, payload);
		if (response?.status === 200) {
			yield put(topicsByPapersRequest(payload?.paperId));
		}
		success("content Updated Successfully");
	} catch (error) {
	} finally {
		yield put(adminTopicsLoading(false));
	}
}

export function* topicDeleteSaga({ payload }) {
	try {
		const response = yield call(topicDeleteById, payload);
		if (response?.status === 200) {
			yield put(topicsByPapersRequest(payload?.paperId));
		}
	} catch (error) {
	} finally {
	}
}

export function* watchAdminTopicSagas() {
	yield takeLatest(allTopicsRequest.type, allTopicsSaga);
	yield takeLatest(singleTopicRequest.type, getSingleTopic);
	yield takeLatest(deleteTopicRequest.type, topicDeleteSaga);
	yield takeLatest(updateTopicsRequest.type, updateTopicSaga);
}
