import { put, call, takeLatest } from "redux-saga/effects";
import {
	allTopicsApi,
	topicById,
	topicDeleteById,
	updateTopic,
} from "../../../api/adminTopicsApi";
import {
	adminTopicsLoading,
	allTopicsDataResponse,
	allTopicsRequest,
	deleteTopicRequest,
	singleTopicRequest,
	topicByIdResponse,
	topicEditDataResponse,
	updateTopicResponse,
	updateTopicsRequest,
} from "../../reducers/duck/adminTopicsDuck";
import { topicsByPapersRequest } from "../../reducers/duck/adminQuestionsDuck";
import { success } from "../../../utils/notifications";
import {
	allSchoolRequest,
	allSchoolResponse,
} from "../../reducers/duck/adminSchoolDuck";
import {
	createSchoolRequest,
	deleteSchoolRequest,
	updateSchoolRequest,
} from "../../reducers/duck/adminSchoolDuck";
import { allSchoolsApi, createSchoolApi } from "../../../api/adminSchoolApi";

// all-topics saga
export function* allSchoolsSaga() {
	try {
		const response = yield call(allSchoolsApi);
		if (response?.data) {
			yield put(allSchoolResponse({ response: response?.data }));
		}
	} catch (error) {
	} finally {
	}
}

export function* createSchoolSaga({ payload }) {
	try {
		yield put(adminTopicsLoading(true));

		const response = yield call(createSchoolApi, payload);
		if (response?.status === 201) {
			yield put(allSchoolRequest());
		}
		success("school created Successfully");
	} catch (error) {
	} finally {
		yield put(adminTopicsLoading(false));
	}
}

// export function* updateSchoolSaga({ payload }) {
// 	try {
// 		yield put(adminTopicsLoading(true));

// 		const response = yield call(createSchoolApi, payload);
// 		if (response?.status === 200) {
// 			yield put(topicsByPapersRequest(payload?.paperId));
// 		}
// 		success("content Updated Successfully");
// 	} catch (error) {
// 	} finally {
// 		yield put(adminTopicsLoading(false));
// 	}
// }

// export function* schoolDeleteSaga({ payload }) {
// 	try {
// 		const response = yield call(topicDeleteById, payload);
// 		if (response?.status === 200) {
// 			yield put(topicsByPapersRequest(payload?.paperId));
// 		}
// 	} catch (error) {
// 	} finally {
// 	}
// }

export function* watchAdminSchoolSagas() {
	yield takeLatest(allSchoolRequest.type, allSchoolsSaga);
	yield takeLatest(createSchoolRequest.type, createSchoolSaga);
	// yield takeLatest(deleteSchoolRequest.type, schoolDeleteSaga);
	// yield takeLatest(updateSchoolRequest.type, updateSchoolSaga);
}
