const urls = {
	auth: {
		login: "auth/login",
		signup: "auth/signup",
	},
	content: {
		subjects: "subjects/with-papers",
		topics: "paper/with-topics",
		contentSummary: "topic/get-content",
	},
	quiz: {
		submitQuiz: "question/submit",
		nextQuestion: "question/nextQuestion",
		reportQuestion: "report-question",
	},
	dashboard: {
		getSubjectPrefrence: "subject-pref/dashboard",
		postSubjectPrefrence: "subject-pref",
		continueQuiz: "user-quiz/get-continue-quiz",
		quizGraph: "/user-quiz/graph/quiz",
	},
	user: {
		user: "auth/user",
	},
	primiarly: {
		prefernece: "/subject-pref",
		confirmation: "/subjects/primiarly-quiz-confirmation",
		quize: "/subjects/primiarly-quiz",
	},
	admin: {
		userList: "auth/admin/all-users",
		allCombinations: "subjects/allCombinations",
		allTopics: "topic/admin/get-all-topics",
		topicById: "topic/admin",
		reportedQuestions: "report-question/get-all-reportsQuestions",
		allSchools: "schoolUser/findAll",
		createSchools: "schoolUser/create",
	},
	schoolUser: {
		findAll: "/schoolUser/findAll",
	},
};

export default urls;
