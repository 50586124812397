import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	primiarlyConfirmationData: null,
	primiarlyQuizeData: null,
	isPrimarilyLoading: false,
	isPrimarilyQuizeStart: false,
	primarilyQuizeId: null,
	isPrefernceSet: false,
};

export const primiarlySlice = createSlice({
	name: "content",
	initialState: INITIAL_STATE,
	reducers: {
		// response reducers

		primiarlyConfirmationResponse(state, { payload }) {
			return {
				...state,
				primiarlyConfirmationData: payload.response,
			};
		},
		primiarlyQuizeResponse(state, { payload }) {
			return {
				...state,
				primiarlyQuizeData: payload.response,
			};
		},
		primiarlyPrefenceLoading(state, { payload }) {
			return {
				...state,
				isPrimarilyLoading: payload,
			};
		},
		primiarlyQuizeStartResponse(state, { payload }) {
			return {
				...state,
				isPrimarilyQuizeStart: payload,
			};
		},
		primarilyQuizeIdResponse(state, { payload }) {
			return {
				...state,
				primarilyQuizeId: payload,
			};
		},
		isPrefernceSetResponse(state, { payload }) {
			return {
				...state,
				isPrefernceSet: payload,
			};
		},
		// request reducers
		primiarlyQuizeRequest: (state) => state,
		primiarlyConfirmationRequest: (state) => state,
	},
});

export const {
	primiarlyPrefenceLoading,
	primiarlyQuizeRequest,
	primiarlyConfirmationRequest,
	primiarlyConfirmationResponse,
	primiarlyQuizeResponse,
	primiarlyQuizeStartResponse,
	primarilyQuizeIdResponse,
	isPrefernceSetResponse,
} = primiarlySlice.actions;

export default primiarlySlice.reducer;
