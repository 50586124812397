import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
	quizzEditRequest,
	reportedQuestionsRequest,
} from "../../redux/reducers/duck/adminQuestionsDuck";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

const ReportedQusEditModal = (props) => {
	const { showEditModal, handleCloseEditModal, viewData, topicQuizeId } = props;
	const dispatch = useDispatch();

	const [editedData, setEditedData] = useState({
		question: "",
		difficultyLevel: "",
		hint: "",
		explaination: "",
		tag: "",
		answers: [],
	});

	useEffect(() => {
		if (viewData) {
			setEditedData({
				question: viewData.question || "",
				difficultyLevel: viewData.difficultyLevel || "",
				hint: viewData.hint || "",
				explaination: viewData.explaination || "",
				tag: viewData.tag || "",
				answers: viewData.answers || [],
			});
		}
	}, [viewData]);

	// Handle input changes
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setEditedData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	// Handle answer changes
	const handleAnswerChange = (index, value, ans) => {
		setEditedData((prevData) => {
			const updatedAnswers = [...prevData.answers];
			updatedAnswers[index] = {
				id: ans?.id,
				isCorrect: ans?.isCorrect,
				answer: value,
			};
			return {
				...prevData,
				answers: updatedAnswers,
			};
		});
	};

	// Handle form submission
	const handleFormSubmit = () => {
		const quizzId = viewData && viewData?.quizId;
		const quesId = viewData && viewData?.id;
		editedData.quizId = quizzId;
		editedData.id = quesId;

		dispatch(
			quizzEditRequest({ data: editedData, topicQuizeId: topicQuizeId })
		);
		dispatch(reportedQuestionsRequest());
		handleCloseEditModal();
	};
	return (
		<>
			<Modal
				show={showEditModal}
				onHide={handleCloseEditModal}
				backdrop="static"
				centered
				aria-labelledby="contained-modal-title-vcenter"
				className="edit-modal"
			>
				<Modal.Header>
					<Modal.Title>Edit Question</Modal.Title>
					<button className="closeButton" onClick={handleCloseEditModal}>
						x
					</button>
				</Modal.Header>
				<Modal.Body>
					<div>
						<Form.Label>Question</Form.Label>
						<Form.Control
							type="text"
							name="question"
							className="input-fields"
							value={editedData.question}
							onChange={handleInputChange}
						/>

						<Form.Label>Difficulty Level</Form.Label>
						<Form.Control
							type="text"
							name="difficultyLevel"
							className="input-fields"
							value={editedData.difficultyLevel}
							onChange={handleInputChange}
						/>

						<Form.Label>Hint</Form.Label>
						<Form.Control
							type="text"
							name="hint"
							className="input-fields"
							value={editedData.hint}
							onChange={handleInputChange}
						/>

						<Form.Label>Explanation</Form.Label>
						<Form.Control
							type="text"
							name="explaination"
							className="input-fields"
							value={editedData.explaination}
							onChange={handleInputChange}
						/>

						<Form.Label>Tags</Form.Label>
						<Form.Control
							type="text"
							name="tag"
							className="input-fields"
							value={editedData.tag}
							onChange={handleInputChange}
						/>

						<Form.Label>Answers</Form.Label>
						{editedData?.answers?.map((ans, index) => (
							<div key={index}>
								<span>{`Option ${index + 1}: `}</span>
								<Form.Control
									type="text"
									name={`answer-${index}`}
									className="input-fields"
									value={ans?.answer}
									onChange={(e) =>
										handleAnswerChange(index, e.target.value, ans)
									}
								/>
							</div>
						))}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="saveChangesBtn" onClick={handleFormSubmit}>
						Save Changes
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ReportedQusEditModal;
