import Axios from "./axios";
import urls from "./url";

// Signup Api
export const signupApi = async (data) => {
	try {
		const res = await Axios.post(urls.auth.signup, data, {
			withCredentials: true,
		});
		if (res) {
			localStorage.setItem("token", res?.data?.access_token);
			localStorage.setItem("currentRole", res?.data?.roles);
		}
		return res;
	} catch (err) {
		return err;
	}
};

// login api
export const loginApi = async (data) => {
	try {
		const res = await Axios.post(urls?.auth?.login, data, {
			withCredentials: true,
		});
		if (res) {
			localStorage.setItem("token", res?.data?.access_token);
			localStorage.setItem("currentRole", res?.data?.roles);
		}
		return res;
	} catch (err) {
		return err;
	}
};

export const getAllSchoolApi = async (data) => {
	try {
		return await Axios.get(urls?.schoolUser?.findAll, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
	} catch (err) {
		return err;
	}
};
