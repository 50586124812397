import * as actions from "../../reducers/duck/contentDuck";
import { put, call, takeLatest } from "redux-saga/effects";
import { contentSumaryApi } from "../../../api/contentApi";
import {
	preferneceApi,
	primiarlyConfirmationApi,
	primiarlyQuizeApi,
} from "../../../api/primiarlyQuizeApi";
import {
	primiarlyConfirmationRequest,
	primiarlyConfirmationResponse,
	primiarlyPrefenceLoading,
	primiarlyQuizeRequest,
	primiarlyQuizeResponse,
} from "../../reducers/duck/primiarlyQuizeDuck";
import { all } from "axios";
import { getSubjectPrefrenceSaga } from "./dashboardSaga";

// primiarly saga

export function* primiarlyConfirmationSaga({ payload }) {
	try {
		yield put(primiarlyPrefenceLoading(true));
		const response = yield call(primiarlyConfirmationApi, payload);
		if (response?.data) {
			yield all([call(getSubjectPrefrenceSaga)]);
			yield put(primiarlyConfirmationResponse({ response: response?.data }));
		}
		yield put(primiarlyPrefenceLoading(false));
	} catch (error) {
		yield put(primiarlyPrefenceLoading(false));
	} finally {
		yield put(primiarlyPrefenceLoading(false));
	}
}

export function* primiarlyQuizeSaga({ payload }) {
	try {
		yield put(primiarlyPrefenceLoading(true));
		const response = yield call(primiarlyQuizeApi, payload);
		if (response?.data) {
			yield put(primiarlyQuizeResponse({ response: response?.data }));
			yield put(primiarlyConfirmationRequest());
		}
		yield put(primiarlyPrefenceLoading(false));
	} catch (error) {
		yield put(primiarlyPrefenceLoading(false));
	} finally {
		yield put(primiarlyPrefenceLoading(false));
	}
}

export function* watchPrimiarlySagas() {
	yield takeLatest(
		primiarlyConfirmationRequest.type,
		primiarlyConfirmationSaga
	);
	yield takeLatest(primiarlyQuizeRequest.type, primiarlyQuizeSaga);
}
