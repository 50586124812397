import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
	dashboardGraphRequest,
	dashboardPendingQuizRequest,
	dashboardSubjectTopicsRequest,
} from "../../../redux/reducers/duck/dashboardDuck";

export const SubjectToggle = () => {
	const [subject, setSubject] = useState("");
	const [selectedTopic, setSelectedTopic] = useState("");

	const { subjectsData, dashboardSubjectTopicsData } = useSelector(
		({ dashboard, content }) => ({
			subjectsData: content?.subjectsData,
			dashboardSubjectTopicsData: dashboard?.dashboardSubjectTopicsData,
		})
	);

	let subjects = subjectsData?.map((item) => {
		return item?.subject;
	});
	const dispatch = useDispatch();
	const handleSubjectSelect = (item) => {
		if (subject !== item?.name) {
			setSelectedTopic("");
		}
		setSubject(item?.name);
		const subjectID = item?.id;
		dispatch(dashboardSubjectTopicsRequest(subjectID));
		dispatch(dashboardPendingQuizRequest(subjectID));
		dispatch(dashboardGraphRequest());
	};

	const handleTopicSelect = (topic) => {
		const topicID = topic?.id;
		dispatch(dashboardGraphRequest(topicID));
		setSelectedTopic(topic?.name);
	};

	return (
		<div className="dropdowns">
			<Dropdown>
				<Dropdown.Toggle id="dropdown-basic">
					<div className="date">
						<p>{subject || "Subjects"}</p>
					</div>
				</Dropdown.Toggle>

				<Dropdown.Menu>
					{subjects &&
						subjects?.map((item) => {
							return (
								<Dropdown.Item onClick={() => handleSubjectSelect(item)}>
									{item?.name}
								</Dropdown.Item>
							);
						})}
				</Dropdown.Menu>
			</Dropdown>

			<Dropdown>
				<Dropdown.Toggle id="dropdown-basic">
					<div className="date">
						<p>{selectedTopic || "Topics"}</p>
					</div>
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-list">
					{dashboardSubjectTopicsData &&
						dashboardSubjectTopicsData?.topics?.map((item) => {
							return (
								<Dropdown.Item onClick={() => handleTopicSelect(item)}>
									{item?.name}
								</Dropdown.Item>
							);
						})}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};
