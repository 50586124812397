import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { reportQuestionRequest } from "../../redux/reducers/duck/quizDuck";
import { useDispatch } from "react-redux";

const reportedQus = ["Ambiguous question", "Out of slyblus", "Repeated"];

const ReportQuestion = (props) => {
	const { showReportModal, handleCloseReportModal, reportData } = props;
	const dispatch = useDispatch();

	const [selectedItems, setSelectedItems] = useState([]);
	const [textareaValue, setTextareaValue] = useState("");

	// handle select answer
	const handleAnswerChange = (item) => {
		const updatedSelectedItems = [...selectedItems];
		if (updatedSelectedItems.includes(item)) {
			updatedSelectedItems.splice(updatedSelectedItems.indexOf(item), 1);
		} else {
			updatedSelectedItems.push(item);
		}
		setSelectedItems(updatedSelectedItems);
	};

	// handle input change
	const handleTextareaChange = (e) => {
		setTextareaValue(e.target.value);
	};

	const reportedData = {
		reportedOptions: selectedItems,
		reason: textareaValue,
	};
	const reportedQuestionId = reportData && reportData?.id;
	const isSubmitDisabled = !(
		selectedItems.length > 0 || textareaValue.trim() !== ""
	);

	// handle submit
	const handleSubmit = () => {
		dispatch(
			reportQuestionRequest({
				questionId: reportedQuestionId,
				reportedReason: reportedData?.reason,
				ambigiousQus: reportedData?.reportedOptions[0] ?? "",
				outOfSlybusQus: reportedData?.reportedOptions[1] ?? "",
				repeatedQus: reportedData?.reportedOptions[2] ?? "",
			})
		);
		handleCloseReportModal();
		setSelectedItems([]);
		setTextareaValue("");
	};

	return (
		<>
			<Modal
				show={showReportModal}
				onHide={handleCloseReportModal}
				backdrop="static"
				centered
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
			>
				<Modal.Header>
					<Modal.Title>Report Question</Modal.Title>
					<button className="closeButton" onClick={handleCloseReportModal}>
						x
					</button>
				</Modal.Header>
				<Modal.Body>
					<div className="reported-question-head">
						<label className="mb-3 heading">
							Select an option or write your reason to report this question
						</label>
						<Form>
							<div className="report-option-list">
								{reportedQus?.map((item, index) => {
									return (
										<Form.Check
											label={item}
											name="group"
											type="checkbox"
											id={index + 1}
											value={item?.answer}
											onChange={() => handleAnswerChange(item)}
											checked={selectedItems.includes(item)}
										/>
									);
								})}
							</div>
							<div className="mt-4 textarea-box">
								<Form.Group
									className="mb-3"
									controlId="exampleForm.ControlTextarea1"
								>
									<Form.Label className="heading">
										Write your reason here
									</Form.Label>
									<Form.Control
										className="complain-textarea mt-2"
										as="textarea"
										type="text"
										rows="20"
										value={textareaValue}
										onChange={handleTextareaChange}
									/>
								</Form.Group>
							</div>
						</Form>
						<button
							className={`reportSubmitBtn ${
								isSubmitDisabled ? "disabled" : ""
							}`}
							disabled={isSubmitDisabled}
							onClick={handleSubmit}
						>
							Submit
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ReportQuestion;
