import Login from "../../modules/users/auth/login";
import SignUp from "../../modules/users/auth/signup";
import Content from "../../modules/users/Content/Content";
import ContentPaper from "../../modules/users/Content/ContentPaper/ContentPaper";
import ContentSummary from "../../modules/users/Content/ContentSummary/ContentSummary";
import Dashboard from "../../modules/users/Dashboard/Dashboard";
import AdminDashboard from "../../modules/admin/dashboard";
import QuizResult from "../../modules/users/QuizResult";
import QuizQuestion from "../../modules/users/QuizQuestion/QuizQuestion";
import AdminUsers from "../../modules/admin/users";
import AdminQuestions from "../../modules/admin/questions";
import AdminTopics from "../../modules/admin/topics";
import ReportedQuestions from "../../modules/admin/reportedQuestions";
import schools from "../../modules/admin/schools";

const userRoutes = [
	{
		path: "/dashboard",
		component: Dashboard,
	},
	{
		path: "/content",
		component: Content,
	},
	{
		path: "/quiz",
		component: ContentPaper,
	},
	{
		path: "/quiz/question",
		component: QuizQuestion,
	},
	{
		path: "/quiz/results",
		component: QuizResult,
	},
	{
		path: "/content/summary",
		component: ContentSummary,
	},
	{
		path: "/quiz/question",
		component: QuizQuestion,
	},
];

const adminRoutes = [
	{
		path: "/dashboard",
		component: AdminDashboard,
	},
	{
		path: "/users",
		component: AdminUsers,
	},
	{
		path: "/questions",
		component: AdminQuestions,
	},
	{
		path: "/topics",
		component: AdminTopics,
	},
	{
		path: "/reported",
		component: ReportedQuestions,
	},
	{
		path: "/schools",
		component: schools,
	},
];

const authRoutes = [
	{
		path: "/signup",
		component: SignUp,
	},
	{
		path: "/login",
		component: Login,
	},
];

export { authRoutes, userRoutes, adminRoutes };
