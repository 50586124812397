import React from "react";
import Courses from "./Courses/Courses";
import Charts from "./Charts/Charts";
import Feedback from "./Feedback/Feedback";
import { SubjectToggle } from "../../../components/layout/primarilyModal/subjectToggle";

const Dashboard = () => {
	return (
		<>
			<SubjectToggle />
			<Courses />
			<Charts />
			<Feedback />
		</>
	);
};

export default Dashboard;
