import { useEffect, useState } from "react";
import AdminSidebar from "../../components/sidebars/adminSidebar";
import Dropdown from "react-bootstrap/Dropdown";
import Bar from "../../assets/images/bar.png";
import { useDispatch, useSelector } from "react-redux";
import { userRequest } from "../../redux/reducers/duck/userDuck";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

const AdminPanelLayout = ({ children }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [openSidebar, setOpenSidebar] = useState(true);

	const { userData } = useSelector(({ user }) => ({
		userData: user?.userData,
	}));

	useEffect(() => {
		dispatch(userRequest());
	}, [dispatch]);

	const sideBarMenu = () => {
		setOpenSidebar(!openSidebar);
	};

	return (
		<>
			<>
				<div className="panel-wrapper flex flex-wrap column-direction">
					<div className={`panel-sidebar ${openSidebar ? "hide" : "show"}`}>
						<AdminSidebar />
					</div>
					<div
						className={`panel-main-content flex column-direction ${
							openSidebar ? "hide" : "show"
						}`}
					>
						<button
							onClick={sideBarMenu}
							className={`side-menu-btn fa fa-bars ${
								openSidebar ? "hide" : "show"
							}`}
						>
							<img src={Bar} alt="bar" />
						</button>
						<header className="panel-header items-center flex content-justify-between">
							<div className="right-side">
								<Dropdown>
									<Dropdown.Toggle id="dropdown-basic">
										<div className="date">
											<img
												src="./images/avatar-placeholder.jpg"
												alt="Avatar"
												width={100}
											/>
											<p>Hello, {userData?.name}</p>
										</div>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item
											href="/login"
											onClick={() => localStorage.clear()}
										>
											<FontAwesomeIcon
												icon={faRightFromBracket}
												className="me-2"
											/>
											LogOut
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</header>
						<div className="adjust-content-space">{children}</div>
					</div>
				</div>
			</>
		</>
	);
};

export default AdminPanelLayout;
